import React from "react";
// import {
  
//   Form as FormikForm,
//   FormikHelpers,
// } from "formik";
import Breadcrumb from "../components/Breadcrumb"; // Import the Breadcrumb component
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { GarbaFormAddList } from "containers/store";
import GarbaClassIcardForm from "./GarbaClassIcardForm";

interface FormValues {
  name: string;
  mobile: string;
  email: string;
  city: string;
  qty: number;
  photo: any;
  aadhar_card: any;
}

// interface Data {
//   name: string;
//   mobile: string;
//   city: string;
//   email: string;
//   amount: number;
//   quantity: number;
// }

// const initialValues: FormValues = {
//   name: "",
//   mobile: "",
//   email: "",
//   city: "Ahmedabad",
//   qty: 1,
//   aadhar_card: null,
//   photo: null,
// };

// const isFile = (value: any): value is File => {
//   return value && value instanceof File;
// };

// const validationSchema = Yup.object({
//   name: Yup.string().required("Name is required"),
//   mobile: Yup.string()
//     .length(10, "Mobile number must be exactly 10 digits")
//     .matches(/^\d+$/, "Mobile number must be digits only")
//     .required("Mobile number is required"),
//   email: Yup.string()
//     .email("Invalid email format")
//     .matches(
//       /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
//       "Invalid email format"
//     )
//     .max(100, "Email must be at most 100 characters long")
//     .required("Email is required"),
//   city: Yup.string().default("Ahmedabad"),
//   qty: Yup.number()
//     .min(1, "Quantity must be at least 1")
//     .required("Quantity is required"),
//   // aadhar_card: Yup.mixed()
//   // .required("You need to upload your Aadhar Card")
//   // .test("fileSize", "File size is too large", function (value) {
//   //   if (!isFile(value)) return true; // No file uploaded or not a File object
//   //   return value.size <= 1024 * 1024; // 1MB limit
//   // })
//   // .test("fileType", "Unsupported file format", function (value) {
//   //   if (!isFile(value)) return true; // No file uploaded or not a File object
//   //   return ["image/jpg", "image/jpeg", "image/png", "application/pdf"].includes(value.type);
//   // }),
//   // photo: Yup.mixed()
//   //   .required("You need to upload your Photo")
//   //   .test("fileSize", "File size is too large", function (value) {
//   //     if (!isFile(value)) return true; // No file uploaded or not a File object
//   //     return value.size <= 1024 * 1024; // 1MB limit
//   //   })
//   //   .test("fileType", "Unsupported file format", function (value) {
//   //     if (!isFile(value)) return true; // No file uploaded or not a File object
//   //     return ["image/jpg", "image/jpeg", "image/png"].includes(value.type);
//   //   })
// });

const GarbaClassForm: React.FC = () => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  // const [photo, setPhoto] = useState(null);
  // const [aadharCard, setAadharCard] = useState(null);
  // const [formLoading, setformLoading] = useState(false);

 

  // const handleSubmit = (
  //   values: FormValues,
  //   { resetForm }: FormikHelpers<FormValues>
  // ) => {
  //   const payLoad = {
  //     ...values,
  //     photo: photo,
  //     aadhar_card: aadharCard,
  //   };
  //   console.log(photo, "photo");
  //   if (!photo) {
  //     SweetToast.error("Photo Filed is Required");
  //   } else if (!aadharCard) {
  //     SweetToast.error("AadharCard Filed is Required");
  //   } else {
  //     setformLoading(true);
  //     dispatch(GarbaFormAddList(payLoad)).then((data: any) => {
  //       setformLoading(true);

  //       if (data?.payload?.garbaFormAddList?.success) {
  //         SweetToast.success("submit successfully");

  //         navigate("/garba-class-form-list");
  //       } else {
  //         SweetToast.error(data?.payload?.message ?? "Please Try Again Later");
  //       }
  //     });
  //     // SweetToast.success("Submit successful");
  //     // navigate("/garba-class-form-list");
  //   }

  //   console.log(payLoad, "payLoadpayLoad");
  //   // resetForm();
  //   // setQuantity(initialValues.qty);
  //   // setIsVisible(false);
  // };
  const addInitialValues: FormValues = {
    name: "",
    mobile: "",
    email: "",
    city: "Ahmedabad",
    qty: 1,
    aadhar_card: null,
    photo: null,
  };

  return (
    <>
      <div className="container mt-2">
        <div>
          <Breadcrumb
            items={[
              { label: "Back", path: "/garba-class-form-list" },
              { label: "Garba-class-Form", path: "/garba-class-form-add" },
              { label: "", path: "#" },
              // Current page, no link
            ]}
          />
        </div>
        <div className="mt-5 max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mb-2">
          <h2 className="text-2xl font-semibold mb-6 text-center text-blue-600">
            Garba Class Form
          </h2>
          <GarbaClassIcardForm initialValues={addInitialValues} isEdit={false} />
        </div>
      </div>
    </>
  );
};

export default GarbaClassForm;
