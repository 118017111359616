// SectionOrchestaSlider.tsx
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Abhijit from "../../Assets/Artist/Abhijit.png";
import dimple from "../../Assets/Artist/dimpal.png";
import himanshu from "../../Assets/Artist/himanshu.png";
import jitinAmit from "../../Assets/Artist/jitin-Amit.png";
import madhavioza from "../../Assets/Artist/madhavi oza.png";
import mayurisoni from "../../Assets/Artist/mayuri soni.png";
import payal from "../../Assets/Artist/payal.png";
import samirraval from "../../Assets/Artist/samir raval.png";
import shyamghadvi from "../../Assets/Artist/shyam ghadvi.png";
import varshakulkarni from "../../Assets/Artist/varsha-kulkarni.png";

interface OrchestDetail {
  date: string;
  artist: string;
  artistImage: string;
}

const OrchestList: OrchestDetail[] = [
  { date: "03 Oct", artist: "Varsha Kulkarni", artistImage: varshakulkarni },
  { date: "04 Oct", artist: "Jitin-Amit", artistImage: jitinAmit },
  { date: "05 Oct", artist: "Mayuri Soni", artistImage: mayurisoni },
  { date: "06 Oct", artist: "Shyam Ghadvi", artistImage: shyamghadvi },
  { date: "07 Oct", artist: "Madhavi Oza", artistImage: madhavioza },
  { date: "08 Oct", artist: "Himanshu Trivedi", artistImage: himanshu },
  { date: "09 Oct", artist: "Samir Raval", artistImage: samirraval },
  { date: "10 Oct", artist: "Dimple Pancholi", artistImage: dimple },
  { date: "11 Oct", artist: "Payal Vakhariya", artistImage: payal },
  { date: "11 Oct", artist: "Abhijit Rao", artistImage: Abhijit },
];

// Define your slider settings
const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const SectionOrchestaSlider: React.FC = () => {
  return (
    <div className=" container mx-auto px-2 py-6">
      <div className="relative">
        <Slider {...sliderSettings}>
          {OrchestList.map((item) => (
            <div key={item.artist} className="p-4">
              <div className="bg-white p-2 rounded-lg shadow-lg">
                <div className="grid grid-cols-12 gap-4 items-center">
                  <div className="col-span-4">
                    <img
                      src={item.artistImage}
                      alt={item.artist}
                      className="w-full h-20 rounded-lg object-contain"
                    />
                  </div>
                  <div className="col-span-8">
                    <b className="text-red-600 text-sm md:text-base">
                      {item.artist}
                    </b>
                    <p className="text-xs md:text-sm">Date: {item.date}</p>
                    <p className="text-xs md:text-sm">Event: Eka-Ras-Garba</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SectionOrchestaSlider;
