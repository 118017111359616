import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { TermsData } from "../../data/Terms"; // Import the correct export
import { FC } from "react";

interface Props {
  panelClassName?: string;
  data?: typeof TermsData;
}

const AccordionInfoEvent: FC<Props> = ({
  panelClassName = "p-4 pt-3 last:pb-0 text-slate-600 text-sm dark:text-slate-300 leading-6 bg-white",
  data = TermsData, // Default to EventData if no data is provided
}) => {
  return (
    <div className="w-full rounded-2xl space-y-2.5 mt-3">
      {data.map((item:any, index:any) => (
        <Disclosure key={index} defaultOpen={index < 2}>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2 font-medium text-left bg-white hover:bg-white dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75">
                <span>{item.name}</span>
                {open ? (
                  <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                ) : (
                  <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                )}
              </Disclosure.Button>
              <Disclosure.Panel
                className={panelClassName}
                as="div"
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};

export default AccordionInfoEvent;
