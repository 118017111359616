import React from "react";
import EkaVideo from "../../Assets/Video/Eka Ras Garba video.mp4"
import NcPlayIcon from "shared/NcPlayIcon/NcPlayIcon";

const VideoSection: React.FC = () => {
  const [isPlay, setIsPlay] = React.useState(false);

  return (
    <div className="nc-SectionVideos">
      <div className="flex flex-col relative sm:pr-4 sm:py-4 md:pr-6 md:py-6 xl:pr-14 xl:py-14 lg:flex-row">
        {/* <div className="absolute -top-4 -bottom-4 -right-4 w-2/3 rounded-3xl bg-primary-100 z-0 sm:rounded-[50px] md:top-0 md:bottom-0 md:right-0 xl:w-7/12 dark:bg-neutral-800/80"></div> */}
        <div className="flex-grow relative z-10">
          <div
            className="group aspect-w-16 aspect-h-16 sm:aspect-h-9 bg-neutral-800 rounded-3xl overflow-hidden border-4 border-white dark:border-neutral-900 sm:rounded-[50px] sm:border-[10px] z-0"
            title="Featured Video"
          >
            {isPlay ? (
              <video
                className="absolute top-0 left-0 w-full h-full"
                src={EkaVideo}
                title="Video"
                controls
                autoPlay
              />
            ) : (
              <>
                <div
                  onClick={() => setIsPlay(true)}
                  className="cursor-pointer absolute inset-0 flex items-center justify-center z-10"
                >
                  <NcPlayIcon />
                </div>
                <video
                  className="absolute inset-0 w-full h-full object-cover rounded-3xl overflow-hidden z-0"
                  src={EkaVideo}
                  muted
              
              
                  style={{ filter: "blur(10px)", opacity: 0.6 }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
