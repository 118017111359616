// checkoutSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Event } from './types'; // Adjust the import path as necessary

interface CheckoutState {
  Event: Event | null;
  count: number;
  totalPrice: number;
  NetAmount: number;
}

const initialState: CheckoutState = {
  Event: null,
  count: 0,
  totalPrice: 0,
  NetAmount: 0,
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setCheckoutData(state, action: PayloadAction<{ Event: Event; count: number; totalPrice: number; NetAmount: number }>) {
      state.Event = action.payload.Event;
      state.count = action.payload.count;
      state.totalPrice = action.payload.totalPrice;
      state.NetAmount = action.payload.NetAmount;
    },
    clearCheckoutData(state) {
      state.Event = null;
      state.count = 0;
      state.totalPrice = 0;
      state.NetAmount = 0;
    },
  },
});

export const { setCheckoutData, clearCheckoutData } = checkoutSlice.actions;
export default checkoutSlice.reducer;
