import React, { FC } from "react";
import Heading from "shared/Heading/Heading";
import FadeInUpOnScroll from "./Animation/FadeInUpOnScroll";

export interface TrendingProductProps {
  className?: string;
}

const TrendingProduct: FC<TrendingProductProps> = ({ className = "mb-1" }) => {
  return (
    <div className={`flex  justify-center relative ${className}`}>
      <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
        <Heading>Top Upcoming <span className="text-[#172554]">  Events   </span> </Heading>
      </FadeInUpOnScroll>
    </div>
  );
};

export default TrendingProduct;
