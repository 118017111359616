import React from "react";

const Terms = () => {
  return (
    <>
      <div className="container bg-gray-100 mt-2">
        <div className="py-4">
          <div className="text-center p-4 bg-gradient-to-b from-blue-900 to-teal-400 text-white mb-1 rounded-lg shadow-md">
            <h3 className="text-2xl md:text-4xl font-light mb-1">Terms</h3>
          </div>
        </div>
        <div className="mx-auto px-4 pb-4">
          <div className="bg-white shadow-md rounded-lg p-6">
            <div className="mb-6">
              <h5 className="text-lg font-semibold text-gray-800 mb-2">
                Services
              </h5>
              <ol className="list-decimal list-inside space-y-2 text-gray-700 text-justify">
                <li className="py-2">
                  <strong className="font-medium">Service Providers:</strong>{" "}
                  The Website and App facilitate the connection between users
                  and service providers for event-related services. The platform
                  does not endorse or guarantee the quality, suitability, or
                  reliability of any service provider. Users are responsible for
                  conducting their own research and due diligence before
                  engaging with any service provider.
                </li>
                <li className="py-2">
                  <strong className="font-medium">User Content:</strong> Users
                  may have the ability to submit content, such as reviews,
                  ratings, or comments, regarding the services provided by
                  service providers. By submitting content, you grant the
                  event-related platform a non-exclusive, royalty-free license
                  to use, display, and distribute the content for promotional or
                  informational purposes.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-lg font-semibold text-gray-800 mb-2">
                Intellectual Property
              </h5>
              <ol className="list-decimal list-inside space-y-2 text-gray-700 text-justify">
                <li className="py-2">
                  <strong className="font-medium">Ownership:</strong> 
                  Including all content, features, and functionality, the platform and its licensors own and protect them under intellectual property laws. You must not reproduce, modify, distribute, or use any part of the Event related without prior written permission from the platform.
                </li>
                <li className="py-2">
                  <strong className="font-medium">Trademarks:</strong> All trademarks, logos, and service marks displayed are the property of the platform or third parties. You must not use any trademarks or logos without the express written consent of the platform or the respective owners.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-lg font-semibold text-gray-800 mb-2">
                Limitation of Liability
              </h5>
              <ol className="list-decimal list-inside space-y-2 text-gray-700 text-justify">
                <li className="py-2">
                  <strong className="font-medium">Disclaimer:</strong> The platform strives to provide accurate and reliable information but does not guarantee the completeness, accuracy, or availability of the content. Your use of the platform is at your own risk.
                </li>
                <li>
                  <strong className="font-medium">
                    Limitation of Liability:
                  </strong>{" "}
                  To the fullest extent permitted by law, the platform shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of the platform.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-lg font-semibold text-gray-800 mb-2">
                Governing Law and Dispute Resolution
              </h5>
              <ol className="list-decimal list-inside space-y-2 text-gray-700 text-justify">
                <li>
                  <strong className="font-medium">Governing Law:</strong>These Terms shall be governed by and construed in accordance with the laws applicable, without regard to its conflict of laws principles.
                </li>
                <li className="py-2">
                  <strong className="font-medium">Dispute Resolution:</strong>{" "}
                  Any disputes arising out of or relating to these Terms shall be resolved through binding arbitration in accordance with applicable arbitration rules. The arbitration shall take place in [City, State], and the arbitrator's decision shall be final and binding.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-lg font-semibold text-gray-800 mb-2">
                Payments
              </h5>
              <ol className="list-decimal list-inside space-y-2 text-gray-700 text-justify">
                <li className="py-2">
                  <strong className="font-medium">Service Fees:</strong> The
                  The platform may charge service fees for the use of certain features or services. The applicable fees will be clearly communicated to you before initiating any payment.
                </li>
                <li className="py-2">
                  <strong className="font-medium">Pricing and Currency:</strong>{" "}
                  All prices and fees displayed on the Platform are as
                  per the event country currency unless otherwise specified. The
                  Platform  reserves the right to modify prices or fees at any
                  time. Any changes will be communicated to you in advance.
                </li>
                <li className="py-2">
                  <strong className="font-medium">Payment Methods:</strong> The
                  platform accepts various payment methods, such as credit cards,
                  debit cards, and electronic payment processors. You must
                  provide accurate and valid payment information when making a
                  payment.
                </li>
                <li className="py-2">
                  <strong className="font-medium">Authorization:</strong> By
                  initiating a payment, you authorize the Platform or its payment
                  service provider to charge the designated payment method for
                  the total amount due, including any applicable taxes or fees.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-lg font-semibold text-gray-800 mb-2">
                Refunds and Cancellations
              </h5>
              <ol className="list-decimal list-inside space-y-2 text-gray-700 text-justify">
                <li className="py-2">
                  <strong className="font-medium">Service Fees:</strong> All
                  fees and charges paid to the Event-related for services rendered are
                  non-refundable unless otherwise explicitly stated in a
                  separate agreement or as required by applicable law.
                </li>
                <li className="py-2">
                  <strong className="font-medium">Cancellations:</strong> Once a
                  service is booked or purchased, cancellation requests will not
                  be accepted, and refunds will not be issued.
                </li>
                <li className="py-2">
                  <strong className="font-medium">No Partial Refunds:</strong>{" "}
                  The Platform does not provide partial refunds for any portion
                  of a service that was not utilized or for any unused features
                  or components of a service.
                </li>
                <li className="py-2">
                  <strong className="font-medium">Cancellation Policy:</strong>{" "}
                  If you need to cancel a service or booking, you must adhere to
                  the cancellation policy stated during the purchase or booking
                  process. Failure to comply with the specified cancellation
                  policy may result in the for feiture of any applicable refund.
                </li>
                <li className="py-2">
                  <strong className="font-medium">
                    Event Cancellation Responsibility:
                  </strong>{" "}
                  If an event is cancelled by the organizer, Event-planner
                  assumes no responsibility. Any refunds or rescheduling must be
                  handled directly through the event organizer according to
                  their policies.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-lg font-semibold text-gray-800 mb-2">
                Payment Security
              </h5>
              <ol className="list-decimal list-inside space-y-2 text-gray-700 text-justify">
                <li>
                  <strong className="font-medium">Secure Transactions:</strong>{" "}
                  The Platform takes reasonable measures to ensure the security
                  of your payment information. All transactions made through the
                  Platform are encrypted and processed in compliance with
                  industry standards.
                </li>
                <li className="py-2">
                  <strong className="font-medium">
                    Third-Party Payment Processors:
                  </strong>{" "}
                  The Platform may utilize third-party payment processors to
                  facilitate transactions. You acknowledge and agree that the
                  Platform is not responsible for the actions or security
                  measures of these third-party processors. Your use of their
                  services is subject to their respective terms and conditions.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-lg font-semibold text-gray-800 mb-2">
                Payment Disputes
              </h5>
              <ol className="list-decimal list-inside space-y-2 text-gray-700 text-justify">
                <li>
                  <strong className="font-medium">Dispute Resolution:</strong>{" "}
                  If you have a dispute regarding payment, please contact the
                  Platform's customer support as soon as possible. We will make
                  reasonable efforts to address and resolve any payment-related
                  issues promptly.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-lg font-semibold text-gray-800 mb-2">
                Safeguarding the Personal Information
              </h5>
              <p className="mb-4 text-gray-700 text-base leading-relaxed text-justify">
                At Platform, your trust is paramount, and we prioritize the
                utmost security and confidentiality of your personal
                information. We value data protection and assure you that your
                privacy is our utmost concern. Personal details collected during
                ticket purchases are used exclusively to enhance your booking
                experience and are never shared with third parties.
              </p>
              <p className="text-gray-700 text-base leading-relaxed text-justify">
                We may seek location permission for tailored event
                recommendations, always respecting your privacy by not
                disclosing this information externally. Our request to read
                messages for OTP auto-fill is optional, convenient, and only used
                within its purpose. Rigorous technical and organizational
                measures safeguard your data, and we comply strictly with data
                protection regulations, ensuring it's not shared with third
                parties unless required by law.
              </p>
            </div>

            <div className="mb-6">
              <h5 className="text-lg font-semibold text-gray-800 mb-2">
                Modifications to Terms
              </h5>
              <p className="text-gray-700 text-base leading-relaxed text-justify">
                The Platform reserves the right to modify or update these Terms
                at any time without prior notice. By continuing to use the
                Platform, you acknowledge and agree to be bound by the
                revised Terms.
              </p>
            </div>

            <div className="mb-6">
              <h5 className="text-lg font-semibold text-gray-800 mb-2">
                Severability and Entire Agreement
              </h5>
              <p className="mb-4 text-gray-700 text-base leading-relaxed text-justify">
                If any provision of these Terms is deemed invalid or
                unenforceable, the remaining provisions shall remain in full
                force and effect. These Terms of Service constitute the entire
                agreement between you and EndlessEvent regarding your use of the
                Website and App.
              </p>
              <p className="text-gray-700 text-base leading-relaxed text-justify">
                Please note that while this version of the Terms of Service is
                inspired by the provided link, it is important to tailor the
                content to your specific requirements and consult legal
                professionals to ensure compliance with applicable laws and
                regulations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
