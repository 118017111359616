import { createSlice } from '@reduxjs/toolkit';

const countSlice = createSlice({
  name: 'count',
  initialState: 1,
  reducers: {
    increment: (state) => state + 1,
    decrement: (state) => (state > 0 ? state - 1 : 0),
  },
});

export const { increment, decrement } = countSlice.actions;
export default countSlice.reducer;
