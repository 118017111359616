import React, { useEffect } from "react";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import FadeInUpOnScroll from "../../components/Animation/FadeInUpOnScroll";
import Location from "../../Assets/svg/location.svg";
import DateImg from "../../Assets/Collection/DateImg.png";
import TimeImg from "../../Assets/Time.png";
import { GetOrderDataByID } from "containers/store";
import { useDispatch, useSelector } from "react-redux";

interface Event {
  name: string;
  image: string;
  ticket_title: string;
  price: number;
  service_fees: number;
  convenience_fees: number;
  singer: string;
  time: string;
  date: string;
  venue: string;
  event_tag: string[];
}
interface LocationState {
  total_price: number;
  event: Event;
  eventTicketData: any;
}

const PaymentDetail: React.FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const getOrderData = useSelector(
    (state: any) => state.persistedReducer.ContainerDataEvent.getOrderDataByID
  );
  console.log(getOrderData, "getOrderData");
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(GetOrderDataByID(id)).then((value: any) => {
        console.log(
          value?.payload?.getOrderDataByID?.data?.event_id,
          "event_id"
        );
      });
    }
  }, [dispatch, id]);
  const handleSubmit = () => {
    // const url = `http://192.168.0.54:8000/online-payment/${getOrderData?.data?.uuid}`;
    const url = `https://api.alabh.com/online-payment/${getOrderData?.data?.uuid}`;
    window.open(url, "_blank");
  };

  const formattedDate = moment(state.event?.date).format("DD-MM-YYYY");

  if (!state) {
    return <div className="text-center">Data not found</div>;
  }

  return (
    <div className="container py-3 bg-[#f1f1f1] mt-3">
      <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="lg:col-span-2">
            <div className="bg-white shadow rounded-lg overflow-hidden">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
                <div className="flex justify-center items-center">
                  <a href="/product-detail">
                    <img
                      className="object-cover w-full h-60 rounded-lg"
                      src={state.event.image}
                      alt="Event Banner"
                    />
                  </a>
                </div>
                <div className="flex flex-col justify-between">
                  <div>
                    
                      <h4 className="text-xl font-bold text-gray-800 truncate">
                        {state.event.name}
                      </h4>
                    

                    <div className="mt-2 flex items-start gap-2">
                      <img src={Location} alt="Location Icon" />
                      <p className="text-gray-600 text-sm">
                        {state.event.venue}
                      </p>
                    </div>
                    <div className="mt-2 flex items-center gap-2">
                      <img src={DateImg} alt="Date Icon" className="w-6 h-6" />
                      <p className="text-gray-600 text-sm">{formattedDate}</p>
                    </div>
                    <div className="mt-2 flex items-center gap-2 px-1">
                      <img src={TimeImg} alt="Time Icon" className="w-5 h-5" />
                      <p className="text-gray-600 text-sm">
                        {state.event.time}
                      </p>
                    </div>
                  </div>

                  <div className="mt-2 text-gray-700">
                    <p className="font-semibold">Hosted By:</p>
                    <p className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">
                      {state.event.name}
                    </p>

                    <p className="font-semibold mt-2">Organized By:</p>
                    <p className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">
                      {state.event.name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:col-span-1">
            <div className="bg-white shadow rounded-lg overflow-hidden">
              <div className="p-4">
                <h5 className="text-xl font-semibold mb-4">Order Summary:</h5>
                <div className="bg-gray-50 p-4 rounded-lg shadow-inner">
                  <h6 className="font-bold text-blue-600 mb-2">Tickets:</h6>
                  <div className=" items-center mb-3">
                    {getOrderData?.data?.order_items?.map((value: any) => {
                      console.log(value, "value");
                      return (
                        <>
                          <div>
                            <p className="mb-0 text-xs text-gray-800 font-bold">
                              {value?.title}
                            </p>
                          </div>
                          <div className="flex justify-between items-center gap-1 mb-3">
                            <div>
                              <p className="text-[#6C757D] capitalize font-bold">
                                {moment(value?.date).format("DD-MM-YYYY")}
                              </p>
                              <p className="mb-0 text-xs text-gray-800 font-bold">
                                {value?.qty} Ticket
                              </p>
                            </div>
                            <div>
                              <p className="mb-0 text-xs text-gray-800 font-bold">
                                ₹{value?.price * value?.qty}
                              </p>
                            </div>
                          </div>
                          <hr className="py-2 border-[#17A2B8]" />
                        </>
                      );
                    })}
                  </div>
                  <div className="flex justify-between items-center mb-3">
                    <p className="font-semibold text-gray-700">Net Amount:</p>
                    <p className="font-semibold text-gray-700">
                      ₹{getOrderData?.data?.total_price}
                    </p>
                  </div>
                  <div className="mt-3">
                    <div className="flex justify-between items-center font-bold text-lg">
                      <p className="font-bold text-blue-600">Amount Payable:</p>
                      <p> ₹{getOrderData?.data?.total_price}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-2 flex justify-center">
                  <div className="text-center p-4">
                    <button
                      type="button"
                      className="min-w-[200px] h-[50px] bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      onClick={handleSubmit}
                    >
                      Proceed to Pay ₹{getOrderData?.data?.total_price}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeInUpOnScroll>
    </div>
  );
};

export default PaymentDetail;
