import { FC } from "react";
import FadeInUpOnScroll from "components/Animation/FadeInUpOnScroll";

export interface ProductCategoryProps {
  className?: string;
}
export interface CardCategoryData {
  Cityname: string;
  Category: string;
  Language: string;
  img?: string;
  Subheading?: string;
}

export interface SectionSliderSolutionsProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: CardCategoryData[];
}

const leadingCities: CardCategoryData[] = [
  {
    Cityname: "Gandhinagar",
    Category: "Arobics",
    Language: "gujarati",
  },
  {
    Cityname: "Ahmedabad",
    Category: "Bollywood",
    Language: "Hindi",
  },
  {
    Cityname: "Imphal",
    Category: "Business",
    Language: "English",
  },
  {
    Cityname: "Chakia",
    Category: "Classical",
    Language: "MultiLanguage",
  },
  {
    Cityname: "Mumbai",
    Category: "Comedy",
    Language: "",
  },
  {
    Cityname: "Puduchery",
    Category: "CrossFit",
    Language: "",
  },
  {
    Cityname: "Vadodara",
    Category: "Dance",
    Language: "",
  },
  {
    Cityname: "Surat",
    Category: "Devotional",
    Language: "",
  },
  {
    Cityname: "Rajkot",
    Category: "Diwali Celebration",
    Language: "",
  },
  {
    Cityname: "Delhi",
    Category: "Drama",
    Language: "",
  },
  {
    Cityname: "Hydrabad",
    Category: "Exhibition",
    Language: "",
  },
  {
    Cityname: "Banlore",
    Category: "All Categories",
    Language: "",
  },
  {
    Cityname: "Indore",
    Category: "",
    Language: "",
  },
  {
    Cityname: "Jaipur",
    Category: "",
    Language: "",
  },
  {
    Cityname: "Udaipur",
    Category: "",
    Language: "",
  },
];

const SectionNewsLetter: FC<SectionSliderSolutionsProps> = ({
  data = leadingCities,
}) => {
  return (
    <>
      <div className="container">
        <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
          <div className="mt-4 ">
            <h6 className="text-slate-800 font-medium mt-2 py-3 ">
              Happenings in Leading Cities
            </h6>
            <div className="flex flex-wrap gap-2">
              {data.map((item, index) => (
                <span
                  key={index}
                  className="mt-2 bg-gray-200 text-gray-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-gray-800 dark:text-gray-300"
                >
                  {item.Cityname}
                </span>
              ))}
            </div>
          </div>
        </FadeInUpOnScroll>
        <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
          <div className="mt-4">
            <h6 className="text-slate-800 font-medium py-3 ">
              Events by Categories
            </h6>
            <div className="flex flex-wrap  gap-2.5">
              {data.map(
                (item, index) =>
                  item.Category && (
                    <span
                      key={index}
                      className="bg-gray-200 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"
                    >
                      {item.Category}
                    </span>
                  )
              )}
            </div>
          </div>
        </FadeInUpOnScroll>
        <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
          <div className="mt-4 mb-4">
            <h6 className="text-slate-800 font-medium py-3 ">
              Events according to Languages
            </h6>
            <div className="flex flex-wrap  items-center gap-2.5">
              {data.map(
                (item, index) =>
                  item.Language && (
                    <span
                      key={index}
                      className="bg-gray-200 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"
                    >
                      {item.Language}
                    </span>
                  )
              )}
            </div>
          </div>
        </FadeInUpOnScroll>
      </div>
    </>
  );
};

export default SectionNewsLetter;
