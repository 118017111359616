import * as React from "react";
import Breadcrumb from "./Breadcrumb";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GetICardFormByID } from "containers/store";
import GarbaClassIcardForm from "./GarbaClassIcardForm";

// interface FormValues {
//   name: string;
//   mobile: string;
//   email: string;
//   city: string;
//   qty: number;
//   photo: any;
//   aadhar_card: any;
// }

const GarbaClassesDetail = () => {
  const [loading, setLoading] = React.useState(true);
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  // const editInitialValues1 = {
  //   name: "John Doe",
  //   mobile: "1234567890",
  //   email: "john.doe@example.com",
  //   city: "Ahmedabad",
  //   qty: 2,
  //   aadhar_card: "path/to/aadhar/card",
  //   photo: "path/to/photo",
  // };
  const [editInitialValues, setEditInitialValues] = React.useState(null);

  // useEffect(() => {

  //   dispatch(GetICardFormByID(id)).then((data: any) => {
  //     setEditInitialValues(data?.payload?.getICardFormByID?.data);
  //     console.log(data?.payload?.getICardFormByID?.data, "getICardFormByID");
  //   });
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await dispatch(GetICardFormByID(id));
        const name = data?.payload?.getICardFormByID?.data?.name || "";
        const [firstName = "", lastName = ""] = name.split(" ");

        const editPayLoad = {
          ...data?.payload?.getICardFormByID?.data,
          firstName: firstName,
          lastName: lastName,
        };
        console.log(editPayLoad, "editPayLoad");
        setEditInitialValues(editPayLoad);
        // console.log(data?.payload?.getICardFormByID?.data, "getICardFormByID");
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, id]);

  if (loading) {
    return (
      <div className="custom-loader-container">
        <div className="custom-loader"></div>
      </div>
    );
  }

  return (
    <div className="container">
      <div>
        <Breadcrumb
          items={[
            { label: "Back", path: "/garba-class-form-list" },
            { label: "", path: "#" },
            // Current page, no link
          ]}
        />
      </div>
      <div className="container">
        {loading ? (
          <div>
            <svg
              className="animate-spin h-5 w-5 mr-2 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
              ></path>
            </svg>
            Loading...
          </div>
        ) : (
          editInitialValues && (
            <GarbaClassIcardForm
              initialValues={editInitialValues}
              isEdit={true}
            />
          )
        )}
      </div>
    </div>
  );
};

export default GarbaClassesDetail;
