import React from "react";

const IcardRules: React.FC = () => {
  return (
    <div className="max-w-lg lg:mx-auto md:mx-auto mx-1 my-4 p-2 bg-white shadow-lg rounded-lg border border-gray-200">
      <h2 className="text-xl font-semibold text-center bg-gray-100 p-2 rounded-md">
        Rules / Regulation
      </h2>
      <div className="h-96 overflow-auto mt-4">
        <ul className="list-disc list-inside space-y-2 text-justify text-sm md:text-base">
          <small className="text-red-500">most important</small>
          <li className="bg-yellow-100">
            Entry will be given only to the person who puts the photograph on
            the I-Card and is wearing traditional dress.
          </li>
          <li>
            It is very important to come to this event in the proper dress.
          </li>
          <li>
            {/* Traditional Garba Attire:
              <ul className="list-disc list-inside ml-6">
                <li>
                  Boys have to wear Kurta Pajama, Pathani, Dhoti or Pajama.
                </li>
                <li>Girls have to wear Lehenga Choli.</li>
              </ul> */}
            Traditional Garba Attire Boys have to wear (Kurta Pajama, Pathani,
            Dhoti or Pajama) is mandatory.
          </li>
          <li>
            On the other hand, Girls have to wear Lehenga Choli, & Attendance of
            80% is compulsory to participate in the Bumper Prize Competition.
          </li>
          <li>
            I-Card is required to be brought at the time of entry in Eka
            Ras-Garba 2024. No entry will be allowed without I-Card.
          </li>
          <li>
            No participants will be allowed to carry polythene bags, snacks, or
            water bottles, tins, cans inside.
          </li>
          <li>
            Khaileya who received any gift or voucher during Navratri should
            contact within fifteen days.
          </li>
          <li>Then the management will have no responsibility.</li>
          <li>
            Management will not have any responsibility if the I-Card is lost.
          </li>
          <li>
            Garba participants must produce the smart card as and when required
            by the organizers/volunteers/security.
          </li>
          <li>
            Timings of Garba shall be as per government rules and regulations.
          </li>
          <li>
            For any dispute or clarifications, the decision of Management shall
            be final. All legal disputes to Ahmedabad Jurisdiction only.
          </li>
          <li>
            Management shall not be responsible to
            participants/invitees/attendants for any damage or injury in person
            or property in the event of any stampede, disruption, civil
            commotion (riot) caused due to individual quarrel, dispute or
            natural events such as rain, fire, earthquake, etc.
          </li>
          <li>
            Participants found under the influence of alcohol/drugs/misbehaving
            will be handed over to the police. The smart I-card will be
            confiscated, and no refund will be given. The management's decision
            in this regard will be final.
          </li>
          <li>
            In case of any calamities/rains/fire or other mishaps and legal
            hurdles, which may cause a change of time, cancellation, or part
            cancellation of the festival, the amount is non-refundable.
          </li>
          <li>
            At the time of completing the online registration, you have to pay
            the I-Card Price.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default IcardRules;
