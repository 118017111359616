import { productImgs } from "contains/fakeData";
import productVariantImg4 from "images/products/v4.jpg";
import productVariantImg5 from "images/products/v5.jpg";
import EventImg1 from "../Assets/Eka-Ras-Garba.jpg";
import EventImg2 from "../Assets/orcheta list 2024-1.png";
import EventImg3 from "../Assets/Ekka-Ras-garba ground.jpg";
import EventImg4 from "../Assets/Eka-ras-garba-Location-img.jpg";

// Define the ticket detail structure
// interface TicketDetail {
//   title: string;
//   price: number;
//   status: string;
//   sellingCloses: string;
//   sellingTime: string;
//   ServiceFees: number;
//   ConvenienceFees: number;
// }

// Define the event structure
// interface Event {
//   id: number;
//   image: string;
//   name: string;
//   quantity: number;
//   Date: string;
//   EventDate: string[];
//   Time: string;
//   Singar: string;
//   Venue: string;
//   EventTag: string[];
//   Rescheduled: string[];
//   ticketDetail: TicketDetail[]; 
// }

export const EventData = [
  // {
  //   id: 1,
  //   image: EventImg1,
  //   name: "EKA RAS-GARBA 2024.... EVENT BY LABH CREATION",
  //   quantity: 1,
  //   Date: " 03'Oct to 11'Oct 2024",
  //   EventDate: [
  //     {
  //       startDate: "03",
  //       startmonth: "oct",
  //       Enddate: "11",
  //       Endmonth: "oct",
  //     },
  //   ],

  //   Time: "3 Hours 30 minutes",
  //   Singar: "Kisan Raval",
  //   EventTag: ["Navratri/Dandiya", "Cultural Festival"],
  //   Venue: "EKA CLUB, Opp. Kankaria Lake Gate No. 3, Kankaria, Ahmedabad.",
  //   Rescheduled: [
  //     {
  //       date: "03'Oct to 11'Oct 2024",
  //       Reason: "Unavoidable circumstances",
  //     },
  //   ],
  //   ticketDetail: [
  //     {
  //       title: "Single Entry",
  //       price: 350,
  //       status: "Hurry, only a few tickets left",
  //       sellingCloses: "2 oct",
  //       sellingTime: "08:30 PM IST",
  //       ServiceFees: 5,
  //       ConvenienceFees: 3,
  //     },
  //   ],
  // },
  {
    id: 1,
    image: EventImg3,
    name: "EKA RAS-GARBA 2024.... EVENT BY LABH CREATION",
    quantity: 1,
    Date: " 03'Oct to 11'Oct 2024",
    EventDate: [
      {
        startDate: "03",
        startmonth: "oct",
        Enddate: "11",
        Endmonth: "oct",
      },
    ],
    Time: "7.30 PM to 12 PM",
    Singar: "Kinjal Dave",
    EventTag: ["Music", "Dance"],
    Venue: "EKA CLUB, Opp. Kankaria Lake Gate No. 3, Kankaria, Ahmedabad.",
    Rescheduled: [
      {
        date: "03'Oct to 11'Oct 2024",
        Reason: "Unavoidable circumstances",
      },
    ],
    ticketDetail: [
      {
        title: "Single Entry",
        price: 350,
        status: "Hurry, only a few tickets left",
        sellingCloses: "2 oct",
        sellingTime: "08:30 PM IST",
        ServiceFees: 0,
        ConvenienceFees: 0,
      },
    ],
  },
  {
    id: 2,
    image: EventImg2,
    name: "EKA RAS-GARBA 2024.... EVENT BY LABH CREATION",
    quantity: 1,
    Date: " 03'Oct to 11'Oct 2024",
    EventDate: [
      {
        startDate: "03",
        startmonth: "oct",
        Enddate: "11",
        Endmonth: "oct",
      },
    ],
    Time: "7.30 PM to 12 PM",
    Singar: "Pravin Barot",
    EventTag: ["orcheta List"],
    Venue: "EKA CLUB, Opp. Kankaria Lake Gate No. 3, Kankaria, Ahmedabad.",
    Rescheduled: [
      {
        date: "03'Oct to 11'Oct 2024",
        Reason: "Unavoidable circumstances",
      },
    ],
    ticketDetail: [
      {
        title: "Single Entry",
        price: 350,
        status: "Hurry, only a few tickets left",
        sellingCloses: "2 oct",
        sellingTime: "08:30 PM IST",
        ServiceFees: 0,
        ConvenienceFees: 0,
      },
    ],
  },
  {
    id: 3,
    image: EventImg4,
    name: "EKA RAS-GARBA 2024.... EVENT BY LABH CREATION",
    quantity: 1,
    Date: " 03'Oct to 11'Oct 2024",
    EventDate: [
      {
        startDate: "03",
        startmonth: "oct",
        Enddate: "11",
        Endmonth: "oct",
      },
    ],
    Time: "7.30 PM to 12 PM",
    Singar: "jitin-Amit",
    EventTag: ["Location"],
    Venue: "EKA CLUB, Opp. Kankaria Lake Gate No. 3, Kankaria, Ahmedabad.",
    Rescheduled: [
      {
        date: "03'Oct to 11'Oct 2024",
        Reason: "Unavoidable circumstances",
      },
    ],
    ticketDetail: [
      {
        title: "Single Entry",
        price: 350,
        status: "Hurry, only a few tickets left",
        sellingCloses: "2 oct",
        sellingTime: "08:30 PM IST",
        ServiceFees: 0,
        ConvenienceFees: 0,
      },
    ],
  },
];

export interface ProductVariant {
  id: number;
  name: string;
  thumbnail?: string;
  color?: string;
  featuredImage: string;
}
export interface Product {
  id: number;
  name: string;
  price: number;
  image: string;
  image1?: string;
  description: string;
  category: string;
  tags: string[];
  link: "/product-detail/";
  variants?: ProductVariant[];
  variantType?: "color" | "image";
  sizes?: string[];
  allOfSizes?: string[];
  sales_rate?: number;
  status?: "New in" | "limited edition" | "Sold Out" | "50% Discount";
}

const DEMO_VARIANTS: ProductVariant[] = [
  {
    id: 1,
    name: "Black",
    thumbnail: EventImg1,
    featuredImage: productImgs[0],
  },
  {
    id: 2,
    name: "White",
    thumbnail: EventImg2,
    featuredImage: productImgs[1],
  },
  {
    id: 3,
    name: "Orange",
    thumbnail: EventImg3,
    featuredImage: productImgs[2],
  },
  {
    id: 4,
    name: "Sky Blue",
    thumbnail: productVariantImg4,
    featuredImage: productImgs[3],
  },
  {
    id: 5,
    name: "Natural",
    thumbnail: productVariantImg5,
    featuredImage: productImgs[4],
  },
];
const DEMO_VARIANT_COLORS: ProductVariant[] = [
  {
    id: 1,
    name: "Violet",
    color: "bg-violet-400",
    featuredImage: productImgs[0],
  },
  {
    id: 2,
    name: "Yellow",
    color: "bg-yellow-400",
    featuredImage: productImgs[1],
  },
  {
    id: 3,
    name: "Orange",
    color: "bg-orange-400",
    featuredImage: productImgs[2],
  },
  {
    id: 4,
    name: "Sky Blue",
    color: "bg-sky-400",
    featuredImage: productImgs[3],
  },
  {
    id: 5,
    name: "Green",
    color: "bg-green-400",
    featuredImage: productImgs[4],
  },
];

export const PRODUCTS: Product[] = [
  {
    id: 1,
    name: "Heritage Garba Patan 2024",
    description: "Heritage Garba Patan 2024",
    price: 103,
    image: EventImg1,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/product-detail/",
    variants: DEMO_VARIANTS,
    variantType: "image",
    // sizes: ["XS", "S", "M", "L", "XL"],
    // allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
    status: "New in",
  },
  {
    id: 2,
    name: "PTZ Dome Camera",
    description: "Dome Camera",
    price: 68,
    image: EventImg2,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/product-detail/",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    status: "50% Discount",
  },
  {
    id: 3,
    name: "CP Plus",
    description: "New blue aqua",
    price: 132,
    image: EventImg3,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/product-detail/",
    variants: DEMO_VARIANTS,
    variantType: "image",
    // sizes: ["S", "M", "L", "XL"],
    // allOfSizes: ["S", "M", "L", "XL", "2XL", "3XL"],
  },
  // {
  //   id: 4,
  //   name: "CP Plus",
  //   description: "Dark pink 2023",
  //   price: 28,
  //   image: productImgs[3],
  //   category: "Category 1",
  //   tags: ["tag1", "tag2"],
  //   variants: DEMO_VARIANT_COLORS,
  //   variantType: "color",
  //   link: "/product-detail/",
  //   status: "Sold Out",
  // },
  // {
  //   id: 5,
  //   name: "CP Plus",
  //   description: "Perfect mint green",
  //   price: 42,
  //   image: productImgs[4],
  //   category: "Category 1",
  //   tags: ["tag1", "tag2"],
  //   variants: DEMO_VARIANTS,
  //   variantType: "image",
  //   // sizes: ["XS", "S", "M", "L", "XL"],
  //   // allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
  //   link: "/product-detail/",
  // },
  // {
  //   id: 6,
  //   name: "CP Plus",
  //   description: "New design 2023",
  //   price: 30,
  //   image: productImgs[5],
  //   category: "Category 1",
  //   tags: ["tag1", "tag2"],
  //   variantType: "color",
  //   variants: DEMO_VARIANT_COLORS,
  //   link: "/product-detail/",
  // },
  // {
  //   id: 9,
  //   name: "CP Plus",
  //   description: "New design 2023",
  //   price: 30,
  //   image: productImgs[5],
  //   category: "Category 1",
  //   tags: ["tag1", "tag2"],
  //   variantType: "color",
  //   variants: DEMO_VARIANT_COLORS,
  //   link: "/product-detail/",
  // },
  // {
  //   id: 7,
  //   name: "CP Plus",
  //   description: "Matte black",
  //   price: 12,
  //   image: productImgs[8],
  //   category: "Category 1",
  //   tags: ["tag1", "tag2"],
  //   variants: DEMO_VARIANTS,
  //   variantType: "image",
  //   link: "/product-detail/",
  //   status: "New in",
  // },
  // {
  //   id: 8,
  //   name: "CP Plus",
  //   description: "Matte black",
  //   price: 12,
  //   image: productImgs[8],
  //   category: "Category 1",
  //   tags: ["tag1", "tag2"],
  //   variants: DEMO_VARIANTS,
  //   variantType: "image",
  //   link: "/product-detail/",
  //   status: "New in",
  // },
  // {
  //   id: 10,
  //   name: "PTZ Dome Camera",
  //   description: "Dome Camera",
  //   price: 68,
  //   image: productImgs[1],
  //   category: "Category 1",
  //   tags: ["tag1", "tag2"],
  //   link: "/product-detail/",
  //   variants: DEMO_VARIANT_COLORS,
  //   variantType: "color",
  //   status: "50% Discount",
  // },
  // {
  //   id: 11,
  //   name: "CP Plus",
  //   description: "New blue aqua",
  //   price: 132,
  //   image: productImgs[15],
  //   category: "Category 1",
  //   tags: ["tag1", "tag2"],
  //   link: "/product-detail/",
  //   variants: DEMO_VARIANTS,
  //   variantType: "image",
  //   // sizes: ["S", "M", "L", "XL"],
  //   // allOfSizes: ["S", "M", "L", "XL", "2XL", "3XL"],
  // },
];
export const SPORT_PRODUCTS: Product[] = [
  {
    id: 1,
    name: "4K Dome Camera",
    description: "Dome Camera",
    price: 103,
    image: productImgs[16],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/product-detail/",
    variants: DEMO_VARIANTS,
    variantType: "image",
    // sizes: ["XS", "S", "M", "L", "XL"],
    // allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
    status: "New in",
  },
  {
    id: 2,
    name: "PTZ Dome Camera",
    description: "Dome Camera",
    price: 68,
    image: productImgs[1],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/product-detail/",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    status: "50% Discount",
  },
  {
    id: 3,
    name: "CP Plus",
    description: "New blue aqua",
    price: 132,
    image: productImgs[15],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/product-detail/",
    variants: DEMO_VARIANTS,
    variantType: "image",
    // sizes: ["S", "M", "L", "XL"],
    // allOfSizes: ["S", "M", "L", "XL", "2XL", "3XL"],
  },
  {
    id: 4,
    name: "CP Plus",
    description: "Dark pink 2023",
    price: 28,
    image: productImgs[3],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    link: "/product-detail/",
    status: "Sold Out",
  },
  {
    id: 5,
    name: "CP Plus",
    description: "Perfect mint green",
    price: 42,
    image: productImgs[4],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    // sizes: ["XS", "S", "M", "L", "XL"],
    // allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
    link: "/product-detail/",
  },
  {
    id: 6,
    name: "CP Plus",
    description: "New design 2023",
    price: 30,
    image: productImgs[5],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variantType: "color",
    variants: DEMO_VARIANT_COLORS,
    link: "/product-detail/",
  },
  {
    id: 7,
    name: "CP Plus",
    description: "Matte black",
    price: 12,
    image: productImgs[8],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/product-detail/",
    status: "New in",
  },
  {
    id: 8,
    name: "CP Plus",
    description: "Matte black",
    price: 12,
    image: productImgs[8],
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/product-detail/",
    status: "New in",
  },
];
