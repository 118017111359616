import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_ENDPOINTS } from "api/apiEndPoints";
import axios from "axios";

export const GetCategoriesByID: any = createAsyncThunk(
  "/categories",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    if (data?.id && data?.currency && data?.page) {
      try {
        const response = await axios.get(
          `${baseUrl}/get-product-by-category/${data?.id}?currency=${data?.currency}&page=${data?.page}`
        );

        // console.log(response?.data, 'res')
        return { getCategoriesByID: response?.data };
      } catch (error) {}
    }
  }
);



export const PostCartItem: any = createAsyncThunk(
  "/CartItem",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(`${baseUrl}/products/cart`, data);
      return { postCartItem: response?.data };
    } catch (error) {}
  }
);
export const SignUpUser: any = createAsyncThunk(
  "/SignUp",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(
        `${baseUrl}${API_ENDPOINTS.register}`,
        data
      );
      return { signUpUser: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const LoginUser: any = createAsyncThunk(
  "/loginUser",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(
        `${baseUrl}${API_ENDPOINTS.login}`,
        data
      );
      return { loginUser: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);



export const ContainerDataSlice = createSlice({
  name: "ContainerData",
  initialState: {
    getCategoriesByID: [],
    postCartItem: [],
    signUpUser: [],
    loginUser: [],
    createOrderData : []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetCategoriesByID.fulfilled, (state, action: any) => {
        state.getCategoriesByID = action.payload.getCategoriesByID;
      })

      .addCase(PostCartItem.pending, (state: any) => {
        state.postCartItemLoader = true;
      })
      .addCase(PostCartItem.fulfilled, (state: any, action: any) => {
        state.postCartItem = action.payload.postCartItem;
        state.postCartItemLoader = false;
      })
      .addCase(PostCartItem.rejected, (state: any) => {
        state.postCartItemLoader = false;
      })
      .addCase(SignUpUser.fulfilled, (state: any, action: any) => {
        state.signUpUser = action.payload.signUpUser;
      })
      .addCase(LoginUser.fulfilled, (state: any, action: any) => {
        state.loginUser = action.payload.loginUser;
      })
     
  },
});

export default ContainerDataSlice.reducer;
