import React, { FC, Fragment, useState, useEffect } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { LoginUser } from "../../containers/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import * as Yup from "yup";
import { useFormik } from "formik";
import Marquee from "react-fast-marquee";
// import { FaParking } from "react-icons/fa";
import "./animationBtn.css";
import useEventData from "api/Event/useEventData";
import AvatarDropdown from "./AvatarDropdown";

export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { events } = useEventData();

  useEffect(() => {
    // Check localStorage or a global state to determine if the user is logged in
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  // const Logout = () => {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("userData");
  //   setIsLoggedIn(false); // Update login state
  //   navigate("/");
  //   localStorage.clear()
  //   window.location.reload()
  // };

  const BackToGarbaClass = () => {
    navigate("/garba-class-form-list");
  };

  const BuyTicket = () => {
    navigate(`/product-detail/${events?.data?.[0]?.id}`);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      // .min(2, "Password must be at least 2 characters")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      dispatch(LoginUser(values)).then((result: any) => {
        setLoading(false);
        if (result?.payload?.loginUser?.success) {
          toast.success(
            result?.payload?.loginUser?.message ?? "Please Try Again Later"
          );
          // SweetToast.success("User Login Successfully");
          localStorage.setItem(
            "token",
            JSON.stringify(result?.payload?.loginUser?.data?.accessToken)
          );
          localStorage.setItem(
            "userData",
            JSON.stringify(result?.payload?.loginUser?.data?.userData)
          );
          setIsLoggedIn(true); // Update login state
          setTimeout(() => {
            navigate("/garba-class-form-list");
            window.location.reload();
            closeDialog(); // Close dialog after successful login
          }, 800);
        } else {
          toast.error(result?.payload?.message ?? "Please Try Again Later");
        }
      });
    },
  });

  return (
    <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
      <div className="container">
        <div className="h-16 flex justify-between items-center">
          <div className="flex items-center justify-between w-full lg:w-auto lg:flex-1">
            <div className="lg:hidden">
              <MenuBar />
            </div>
            <div className="lg:flex-1 flex justify-left">
              <Logo className="flex-shrink-0" />
            </div>

            <div className="flex lg:flex-1  justify-end">
              {/* <Link to="/garbaclass">
              <button
                className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded-lg text-xs md:text-base lg:text-base"
                // onClick={openDialog}
              >
                Garba Classes Sign IN
              </button>
              </Link> */}
              {isLoggedIn ? (
                <div className="flex gap-2">
                  <button
                    className="bg-transparent hover:bg-blue-500 text-blue-700 font-normal md:font-medium hover:text-white py-0 px-1 md:px-6  border border-blue-500 hover:border-transparent rounded-lg text-[10px] md:text-base lg:text-base"
                    onClick={BackToGarbaClass}
                  >
                    Garba Class I-Card List
                  </button>
                  {/* <button
                    className="bg-transparent hover:bg-blue-500 text-blue-700 font-normal md:font-medium hover:text-white py-2 px-1  md:px-6 border border-blue-500 hover:border-transparent rounded-lg text-xs md:text-base lg:text-base"
                    onClick={Logout}
                  >
                    LogOut
                  </button> */}
                  <AvatarDropdown />
                </div>
              ) : (
                <button
                  className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-2  border border-blue-500 hover:border-transparent rounded-lg text-xs md:text-base lg:text-base"
                  onClick={openDialog}
                >
                  Garba Classes Sign IN
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="bg-gray-100 py-1 mb-2 px-4 lg:px-15">
          <div className=" mx-auto flex flex-col lg:flex-row justify-between items-center gap-2 lg:gap-10">
            <div className="flex-1 text-center lg:text-left">
              <h2 className="text-sm md:text-xl lg:text-2xl font-semibold text-gray-800">
                FIND UPCOMING EVENTS NEAR YOU
              </h2>
            </div>
            <div className="flex-shrink-0 flex space-x-5">
              <button className="five cutout" onClick={BuyTicket}>
                Buy Ticket
              </button>
              <Link to="/IcardForm">
                <button className="icardBtn font-bold">
                  Get 9 Day Garba I-card
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="text-white bg-[#df0e19]">
          {/* <Marquee speed={50} gradient={false}>
            Great news! <FaParking className="mx-2" /> Parking for the Eka Club
            event is free for all attendees. We look forward to seeing you
            there!
          </Marquee> */}
          <Marquee speed={50} gradient={false}>
            "First come, first served! Don’t miss out! Early bird tickets
            available for a limited time -  Book Now"
          </Marquee>
        </div>
      </div>
      <Transition appear show={isDialogOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeDialog}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30 " />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto py-4">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 mt-10 md:mt-0 text-left align-middle shadow-xl transition-all z-20 ">
                  <div className="flex justify-between items-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Log in to your account
                    </Dialog.Title>
                    <button
                      onClick={closeDialog}
                      className="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-md"
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="mt-4">
                    <form onSubmit={formik.handleSubmit} className="space-y-6">
                      <label className="block">
                        <span>Email address <span className="text-red-500">*</span></span>
                        <Input
                          type="email"
                          name="email"
                          placeholder="example@example.com"
                          className="mt-1"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-red-500 text-sm mt-1">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </label>
                      <label className="block">
                        <span className="">
                          Password<span className="text-red-500">*</span>
                        </span>
                        <Input
                          type="password"
                          name="password"
                          className="mt-1"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />
                        {/* <Link
                          to="/forgot-pass"
                          onClick={closeDialog}
                          className="text-sm text-green-600"
                        >
                          Forgot password?
                        </Link> */}
                        {formik.touched.password && formik.errors.password ? (
                          <div className="text-red-500 text-sm mt-1">
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </label>
                      {loading ? (
                        <ButtonPrimary loading disabled>
                          Login
                        </ButtonPrimary>
                      ) : (
                        <ButtonPrimary type="submit">Login</ButtonPrimary>
                      )}
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ToastContainer />
    </div>
  );
};

export default MainNav2Logged;
