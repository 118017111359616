import React, { FC, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_ENDPOINTS } from 'api/apiEndPoints';

const ForgotPassword: FC = () => {
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').required('Email is required'),
    new_password: Yup.string().min(6, 'Password must be at least 6 characters').required('New password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password')], 'Passwords must match')  // Remove `null` here
      .required('Confirm password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      new_password: '',
      confirm_password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const response = await axios.post(`${baseUrl}${API_ENDPOINTS.forgotPass}`, values);
        if (response.data.success) {
          toast.success('Password reset successful');
        } else {
          toast.error('Failed to reset password');
        }
      } catch (error) {
        toast.error('An error occurred, please try again');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="container mx-auto p-4 max-w-sm">
      <h2 className="text-2xl font-semibold mb-4">Reset Password</h2>
      <form onSubmit={formik.handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email Address
          </label>
          <input
            id="email"
            type="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${formik.touched.email && formik.errors.email ? 'border-red-500' : ''}`}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
          ) : null}
        </div>
        <div>
          <label htmlFor="new_password" className="block text-sm font-medium text-gray-700">
            New Password
          </label>
          <input
            id="new_password"
            type="password"
            name="new_password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.new_password}
            className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${formik.touched.new_password && formik.errors.new_password ? 'border-red-500' : ''}`}
          />
          {formik.touched.new_password && formik.errors.new_password ? (
            <div className="text-red-500 text-sm mt-1">{formik.errors.new_password}</div>
          ) : null}
        </div>
        <div>
          <label htmlFor="confirm_password" className="block text-sm font-medium text-gray-700">
            Confirm Password
          </label>
          <input
            id="confirm_password"
            type="password"
            name="confirm_password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirm_password}
            className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${formik.touched.confirm_password && formik.errors.confirm_password ? 'border-red-500' : ''}`}
          />
          {formik.touched.confirm_password && formik.errors.confirm_password ? (
            <div className="text-red-500 text-sm mt-1">{formik.errors.confirm_password}</div>
          ) : null}
        </div>
        <button
          type="submit"
          className={`w-full py-2 px-4 rounded-md bg-blue-500 text-white font-semibold ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={loading}
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
