import { useQuery } from "@tanstack/react-query";
import { baseUrl, token } from "api";
import { API_ENDPOINTS } from "../apiEndPoints";
import axios from "axios";

const fetchEvent = async () => {
  const response = await axios.get(`${baseUrl}${API_ENDPOINTS.Event}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const useEventData = () => {
  const { data: events, isLoading, error } = useQuery({
    queryKey: ["Event"], // Adjust queryKey as per your API requirements
    queryFn: fetchEvent,
    staleTime: 1000 * 60 * 5, // Adjust staleTime as needed
  });

  return { events, isLoading, error };
};

export default useEventData;

