import React, { useEffect, useState } from "react";
import "./tabSlide.css";
import moment from "moment";

interface TabItem {
  id: string;
  date: string;
  title: string;
  price: string;
  qty: number;
}

interface TabProps {
  onSubmit: any; // Adjust this type based on the actual function signature
  GetEventDetailData: any; // Adjust this type based on the actual function signature
}

function Tab({ onSubmit, GetEventDetailData }: TabProps) {
  console.log(GetEventDetailData?.date, "Start_date");
  console.log(GetEventDetailData?.end_date, "end_date");
  // const [data, setData] = useState<TabItem[]>([
  //   {
  //     id: "1",
  //     date: "3 Oct",
  //     title: "EKA RAS-GARBA 2024, EVENT BY LABH CREATION ( October - 03 )",
  //     price: "400",
  //     qty: 0,
  //   },
  //   {
  //     id: "2",
  //     date: "4 Oct",
  //     title: "EKA RAS-GARBA 2024, EVENT BY LABH CREATION ( October - 04 )",
  //     price: "400",
  //     qty: 0,
  //   },
  //   {
  //     id: "3",
  //     date: "5 Oct",
  //     title: "EKA RAS-GARBA 2024, EVENT BY LABH CREATION ( October - 05 )",
  //     price: "400",
  //     qty: 0,
  //   },
  //   {
  //     id: "4",
  //     date: "6 Oct",
  //     title: "EKA RAS-GARBA 2024, EVENT BY LABH CREATION ( October - 06 )",
  //     price: "400",
  //     qty: 0,
  //   },
  //   {
  //     id: "5",
  //     date: "7 Oct",
  //     title: "EKA RAS-GARBA 2024, EVENT BY LABH CREATION ( October - 07 )",
  //     price: "400",
  //     qty: 0,
  //   },
  //   {
  //     id: "6",
  //     date: "8 Oct",
  //     title: "EKA RAS-GARBA 2024, EVENT BY LABH CREATION ( October - 08 )",
  //     price: "400",
  //     qty: 0,
  //   },
  //   {
  //     id: "7",
  //     date: "9 Oct",
  //     title: "EKA RAS-GARBA 2024, EVENT BY LABH CREATION ( October - 09 )",
  //     price: "400",
  //     qty: 0,
  //   },
  //   {
  //     id: "8",
  //     date: "10 Oct",
  //     title: "EKA RAS-GARBA 2024, EVENT BY LABH CREATION ( October - 10 )",
  //     price: "400",
  //     qty: 0,
  //   },
  //   {
  //     id: "9",
  //     date: "11 Oct",
  //     title: "EKA RAS-GARBA 2024, EVENT BY LABH CREATION ( October - 11 )",
  //     price: "400",
  //     qty: 0,
  //   },
  // ]);
  const [data, setData] = useState<TabItem[]>([]);
  console.log(data, "data");
  useEffect(() => {
    if (GetEventDetailData?.date && GetEventDetailData?.end_date) {
      console.log(GetEventDetailData?.date, "Start_date");
      console.log(GetEventDetailData?.end_date, "end_date");
      console.log(GetEventDetailData?.price, "price");

      const startDate = moment(GetEventDetailData.date, "YYYY-MM-DD");
      const endDate = moment(GetEventDetailData.end_date, "YYYY-MM-DD");
      const daysBetween = endDate.diff(startDate, "days");

      // Convert price to a fixed two decimal place string
      const price =
        typeof GetEventDetailData?.price === "number"
          ? GetEventDetailData?.price.toFixed(2)
          : parseFloat(GetEventDetailData?.price || "0").toFixed(2);

      // Get month and year dynamically from startDate
      const monthName = startDate.format("MMMM"); // e.g., "October"
     

      const eventArray: TabItem[] = Array.from({ length: daysBetween + 1 }).map(
        (_, index) => {
          const currentDate = moment(GetEventDetailData.date).add(
            index,
            "days"
          );
          const formattedDate = currentDate.format("D MMM"); // Format as "3 Oct", "4 Oct", etc.
          const dayOfMonth = currentDate.format("DD"); // Extract day of the month

          return {
            id: (index + 1).toString(),
            date: formattedDate,
            title: `${GetEventDetailData?.name} ( ${monthName} - ${dayOfMonth} )`,
            price: price,
            qty: 0,
          };
        }
      );

      // Update the state with the dynamically generated event data
      setData(eventArray);
    }
  }, [GetEventDetailData]);

  const showButton = data.some((item) => item.qty > 0);

  const [visibleTab, setVisibleTab] = useState<any>(data[0]?.id ?? "1");
  // console.log(visibleTab, "visibleTab");
  const handleIncrement = (id: string) => {
    setData((prevData) =>
      prevData?.map((item) =>
        item.id === id ? { ...item, qty: item.qty + 1 } : item
      )
    );
  };

  const handleDecrement = (id: string) => {
    setData((prevData) =>
      prevData?.map((item) =>
        item.id === id && item.qty > 0 ? { ...item, qty: item.qty - 1 } : item
      )
    );
  };

  const formattedPrice = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(GetEventDetailData?.price);

  const listTitles = data?.map((item) => (
    <li
      key={item.id}
      onClick={() => setVisibleTab(item.id)}
      className={
        visibleTab === item.id ? "tab-title tab-title--active" : "tab-title"
      }
    >
      {item.date}
    </li>
  ));

  const listContent = data?.map((item) => (
    <div
      key={item.id}
      style={visibleTab === item.id ? {} : { display: "none" }}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
        <div className="flex flex-col justify-between">
          <div className="flex items-center gap-2 mb-2">
            <h6 className="text-uppercase mb-0 pt-1 font-bold">{item.title}</h6>
          </div>
          <p className="text-sm mb-3 mt-2">Valid Only {item?.date}</p>
        </div>

        <div className="flex justify-between items-center md:items-end">
          <div className="flex flex-col items-center text-center">
            {/* <h6 className="font-bold mb-0">₹{item?.price}</h6> */}
            <h6 className="font-bold mb-0">{formattedPrice}</h6>
          </div>
          <div className="flex items-center gap-2 mb-2">
            <button
              type="button"
              className="p-0 flex items-center justify-center rounded-full h-9 w-9 bg-gray-600"
              onClick={() => handleDecrement(item.id)}
            >
              <svg
                className="w-5 h-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <title>minus</title>
                <path d="M19,13H5V11H19V13Z" fill="currentColor"></path>
              </svg>
            </button>
            <div className="flex items-center justify-center rounded-full h-9 w-9 bg-gray-200">
              {item.qty}
            </div>
            <button
              type="button"
              className="p-0 flex items-center justify-center rounded-full h-9 w-9 bg-blue-600"
              onClick={() => handleIncrement(item.id)}
            >
              <svg
                className="w-5 h-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
                width="48"
              >
                <path
                  fill="currentColor"
                  d="M433-183v-250H183v-94h250v-250h94v250h250v94H527v250h-94Z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  ));
  const handleSubmit = () => {
    const filterData = data?.filter((value) => {
      return value.qty > 0;
    });
    onSubmit(filterData);
  };

  return (
    <>
      <div className="tabs">
        <ul className="tabs-titles">{listTitles}</ul>
        <div className="tab-content-add ">{listContent}</div>
      </div>
      <div className="flex justify-end ">
        {showButton && (
          <button
            type="button"
            className="w-36 px-4 py-2 my-5 bg-blue-500 text-white rounded"
            onClick={handleSubmit}
          >
            Next
          </button>
        )}
      </div>
    </>
  );
}

export default Tab;
