import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import AccordionInfoEvent from "../../containers/ProductDetailPage/AccordionInfoEvent";

const DUMMY_OTP = "123456";

const Help: React.FC = () => {
  const fakeData = [
    {
      name: "Is my personal information secure on Eka Event?",
      content:
        "We take the security and privacy of your personal information seriously. Our platform utilizes industry-standard security measures to protect your data.",
    },
    {
      name: "Is my payment information secure on Eka Event?",
      content:
        "We prioritize the security of your payment information. All transactions made through our platform occur via a secure third-party payment gateway, ensuring your financial data is protected.",
    },
    {
      name: "How can I buy tickets for an event?",
      content:
        "Buying tickets on Eka Event is easy! First, find the event you're interested in from the list of available events. Click on the event to view its details, then select the ticket type and the members. Proceed to the payment gateway, enter your payment details, and confirm your purchase. You will receive a confirmation email with your e-ticket, which you can either print or display on your mobile device at the event.",
    },
    {
      name: "What if I have a complaint or issue with event organizers?",
      content:
        "If you encounter any issues with a service provider, please let us know as soon as possible. We take feedback seriously and will work with you and the service provider to resolve the matter.",
    },
    {
      name: "Can I trust the event organizers listed on Eka Event?",
      content:
        "We take great care in vetting and curating our list of service providers. However, it is essential to conduct your own due diligence and review from other users before making a decision. We encourage you to communicate with the service provider, ask for references, and verify their credentials to ensure a positive experience.",
    },
    {
      name: "Are the event organizers verified on Eka Event?",
      content:
        "We take the verification process seriously to maintain the quality of our services. Event organizers must pass through our verification process, which includes checking their credentials and reviewing their past events. However, we recommend exercising your own judgment and reading reviews to make informed decisions.",
    },
  ];

  const [otpError, setOtpError] = useState("");

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .max(20, "Max 20 characters")
      .required("First Name is required"),
    lastName: Yup.string()
      .max(20, "Max 20 characters")
      .required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .max(30, "Max 30 characters")
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number should be exactly 10 digits")
      .required("Phone is required"),
    otp: Yup.string()
      .matches(/^[0-9]{6}$/, "OTP should be exactly 6 digits")
      .required("OTP is required"),
    contactRadio: Yup.string().required("Please select an option"),
    typeOfIssue: Yup.string().required("Please select the type of issue"),
    subject: Yup.string()
      .max(50, "Max 50 characters")
      .required("Subject is required"),
    comment: Yup.string()
      .max(200, "Max 200 characters")
      .required("Comment is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      otp: "",
      contactRadio: "Support",
      typeOfIssue: "",
      subject: "",
      comment: "",
      attachments: null,
    },
    validationSchema,
    onSubmit: (values) => {
      // OTP validation
      if (values.otp !== DUMMY_OTP) {
        setOtpError("Invalid OTP, please try again");
      } else {
        setOtpError("");
        alert("Form submitted successfully!");
        console.log("Form Data:", values);
      }
    },
  });

  return (
    <div className="container py-4">
      <div className="text-center p-4 bg-gradient-to-b from-blue-900 to-teal-400 text-white mb-1 rounded-lg shadow-md">
        <h3 className="text-3xl md:text-4xl font-light mb-1">Support Center</h3>
        <p>Trust is important to us. Excuses are unacceptable.</p>
      </div>

      <div className="container mx-auto px-4 py-3 pb-4 bg-gray-100">
        <div className="bg-gray-100">
          <div className="card p-6 bg-white rounded-lg shadow-md">
            <div className="flex flex-col md:flex-row items-start gap-6">
              {/* Customer Support Section */}
              <div className="md:w-1/2">
                <h5 className="text-lg font-semibold mb-1">
                  Customer Support:
                </h5>
                <p className="text-gray-700">
                  Our team is glad to assist you. <br />
                  Reach out to our customer support for any queries.
                </p>
              </div>

              {/* Address Section */}
              <div className="md:w-1/2">
                <h5 className="text-lg font-semibold mb-2">Address:</h5>
                <p className="text-gray-700">
                  4th Floor, Pramukh Mastana Complex,
                  <br />
                  Kudasan, Gandhinagar- 382421, Gujarat, India
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 mt-2">
          {/* Contact Form Card */}
          <div className="card p-6 bg-white rounded-lg shadow-md">
            <h5 className="text-lg font-semibold mb-2">
              Fill out the form below to send us a message:
            </h5>
            <hr className="border-dashed border-gray-300 my-4" />
            {/*start Form for send Message */}
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                {/* First Name Field */}
                <div className="mb-2">
                  <label className="block text-sm font-medium mb-2">
                    First Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength={20}
                    placeholder="Enter your first name here..."
                    {...formik.getFieldProps("firstName")}
                    className={`form-input mt-1 block w-full border ${
                      formik.touched.firstName && formik.errors.firstName
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-lg`}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.firstName}
                    </div>
                  ) : null}
                </div>

                {/* Last Name Field */}
                <div className="mb-2">
                  <label className="block text-sm font-medium mb-2">
                    Last Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength={20}
                    placeholder="Enter your last name here..."
                    {...formik.getFieldProps("lastName")}
                    className={`form-input mt-1 block w-full border ${
                      formik.touched.lastName && formik.errors.lastName
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-lg`}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.lastName}
                    </div>
                  ) : null}
                </div>

                {/* Email Field */}
                <div className="mb-2">
                  <label className="block text-sm font-medium mb-2">
                    Email <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    maxLength={50}
                    placeholder="Enter your email here..."
                    {...formik.getFieldProps("email")}
                    className={`form-input mt-1 block w-full border ${
                      formik.touched.email && formik.errors.email
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-lg`}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>

                {/* Phone Field */}
                <div className="mb-2">
                  <label className="block text-sm font-medium mb-2">
                    Phone <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="tel"
                    placeholder="Enter your phone here..."
                    {...formik.getFieldProps("phone")}
                    className={`form-input mt-1 block w-full border ${
                      formik.touched.phone && formik.errors.phone
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-lg`}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.phone}
                    </div>
                  ) : null}
                </div>

                {/* OTP Field */}
                <div className="mb-2">
                  <label className="block text-sm font-medium mb-2">
                    OTP <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="password"
                    placeholder="Enter your OTP here..."
                    {...formik.getFieldProps("otp")}
                    className={`form-input mt-1 block w-full border ${
                      formik.touched.otp && (formik.errors.otp || otpError)
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-lg`}
                  />
                  {formik.touched.otp && formik.errors.otp ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.otp}
                    </div>
                  ) : otpError ? (
                    <div className="text-red-500 text-sm">{otpError}</div>
                  ) : null}
                </div>

                {/* Contact Radio Options */}
                <div className="mb-2">
                  <label className="block text-sm font-medium mb-2">
                    Contacting us for:
                  </label>
                  <div className="flex flex-row md:flex-col lg:flex-row items-center md:items-start gap-4">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        {...formik.getFieldProps("contactRadio")}
                        value="Support"
                        className="form-radio"
                        checked={formik.values.contactRadio === "Support"}
                      />
                      <label className="ml-2">Support</label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        {...formik.getFieldProps("contactRadio")}
                        value="Feedback"
                        className="form-radio"
                        checked={formik.values.contactRadio === "Feedback"}
                      />
                      <label className="ml-2">Feedback</label>
                    </div>
                  </div>
                </div>

                {/* Type of Issue Field */}
                <div className="mb-2">
                  <label className="block text-sm font-medium mb-2">
                    Type of Issue <span className="text-red-500">*</span>
                  </label>
                  <select
                    {...formik.getFieldProps("typeOfIssue")}
                    className={`form-select mt-1 block w-full border ${
                      formik.touched.typeOfIssue && formik.errors.typeOfIssue
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-lg`}
                  >
                    <option value="">Select an option</option>
                    <option value="Events & Ticketing">
                      Events & Ticketing
                    </option>
                    <option value="Issues/Complaints">Issues/Complaints</option>
                    <option value="others">Others</option>
                  </select>
                  {formik.touched.typeOfIssue && formik.errors.typeOfIssue ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.typeOfIssue}
                    </div>
                  ) : null}
                </div>

                {/* Subject Field */}
                <div className="mb-2">
                  <label className="block text-sm font-medium mb-2">
                    Subject <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength={50}
                    placeholder="Enter your Subject here..."
                    {...formik.getFieldProps("subject")}
                    className={`form-input mt-1 block w-full border ${
                      formik.touched.subject && formik.errors.subject
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-lg`}
                  />
                  {formik.touched.subject && formik.errors.subject ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.subject}
                    </div>
                  ) : null}
                </div>

                {/* Comment Field */}
                <div className="mb-2">
                  <label className="block text-sm font-medium mb-2">
                    Comment <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    rows={3}
                    maxLength={200}
                    placeholder="Enter your comment here..."
                    {...formik.getFieldProps("comment")}
                    className={`form-textarea mt-1 block w-full border ${
                      formik.touched.comment && formik.errors.comment
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-lg`}
                  />
                  {formik.touched.comment && formik.errors.comment ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.comment}
                    </div>
                  ) : null}
                </div>

                {/* Attachments Field */}
                <div className="mb-2">
                  <label className="block text-sm font-medium mb-2">
                    Attachments
                  </label>
                  <input
                    type="file"
                    multiple
                    name="attachments"
                    onChange={(event) =>
                      formik.setFieldValue(
                        "attachments",
                        event.currentTarget.files
                      )
                    }
                    className="form-input mt-1 block w-full border border-gray-300 rounded-lg"
                  />
                  <p className="mt-1 text-sm text-gray-600">
                    The <b>maximum image size</b> should be <b>2 MB</b> and
                    files allowed are <b>jpg, jpeg, png, pdf</b>.<p className="text-red-300">Not required</p>
                  </p>
                </div>
                <div className="mb-0"></div>
                {/* Submit Button */}
                <div className="flex justify-center items-center">
                  <button
                    type="submit"
                    className="bg-blue-500 text-white py-2 px-6 rounded-lg shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300 ease-in-out"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
            {/*end Form for send Message */}
          </div>

          {/* FAQ Card */}
          <div className="card p-6 bg-white rounded-lg shadow-md">
            <h5 className="text-lg font-semibold mb-2">
              Commonly Asked Questions:
            </h5>
            <hr className="border-dashed border-gray-300 my-4" />
            <AccordionInfoEvent data={fakeData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
