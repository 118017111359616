import React from 'react';
import { Link } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

interface BreadcrumbItem {
  label: string;
  path: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <nav aria-label="breadcrumb" className="p-3 rounded-md shadow-sm">
      <ol className="breadcrumb flex items-center space-x-2 text-xs sm:text-sm whitespace-nowrap overflow-hidden">
        {items.map((item, index) => (
          <li
            key={index}
            className={`flex items-center ${index === items.length - 1 ? 'font-semibold text-gray-700' : 'text-blue-600'}`}
          >
            {index < items.length - 1 ? (
              <>
                <Link to={item.path} className="hover:underline truncate">
                  {item.label}
                </Link>
                <FaChevronRight className="mx-2 text-gray-500" />
              </>
            ) : (
              <span className="truncate">{item.label}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
