//PageHome.tsx
// import React from "react";
import SectionGridFeatureItems from "./SectionGridFeatureItems";
import SectionEventHero from "../../components/SectionHero/SectionEventHero";
import VideoSection from "./VideoSection";
import SectionOrchestaSlider from "components/SectionHero/SectionorchestaSlider";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden py-4">
      {/* <div>
        <TicketDesign />
      </div> */}
      {/* SECTION HERO */}
      <div className="container">
        <SectionEventHero />
      </div>
      <div className="container mt-2">
        <div className="bg-slate-100">
        <SectionOrchestaSlider />
        </div>
      </div>
      <div className="container py-3">
        <VideoSection />
      </div>
      {/* <SectionHero2 /> */}
      {/* <div className="container space-y-12 my-12 lg:space-y-22 lg:my-22">
        <SectionProductCategory />
      </div> */}
      <div className="container relative space-y-10 my-10 lg:space-y-10 lg:my-10">
        <SectionGridFeatureItems />
      </div>

      {/* <div className="bg-white">
        <div className="container my-10 py-5">
          <SectionSliderSolutions />
        </div>
      </div> */}
      {/* <div className="bg-slate-200 p-4">
        <SectionSupport />
      </div> */}
      {/* <div className="py-10">
        <SectionNewsArticle />
      </div> */}

      {/* <SectionNewsLetter /> */}
    </div>
  );
}

export default PageHome;
