import React from "react";
import { Link } from "react-router-dom";
import instagram from "../../Assets/instagram-logo copy.jpg";
import facebook from "../../Assets/svg/facebook copy.svg";
import whatsapp from "../../Assets/svg/whatsapp.svg";
import visaLogo from "images/Visa.png";
import upiLogo from "images/UPI.webp";
import MasterCardLogo from "images/MasterCardLogo.png";
import ruPayLogo from "images/Rupay-Logo.png";
import AmeriCan from "images/AmeriCan.png";
import Payment from "images/Payment.svg";
import FadeInUpOnScroll from "../../components/Animation/FadeInUpOnScroll";
import Logo from "shared/Logo/Logo";

const Footer: React.FC = () => {
  const paymentLogo = [
    upiLogo,
    visaLogo,
    ruPayLogo,
    MasterCardLogo,
    AmeriCan,
    Payment,
  ];
  return (
    <>
      <div className="container mb-4 border-t pt-4 ">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-5">
          <div className="text-secondary">
            <a href="/">
              <FadeInUpOnScroll
                defaultClassName="animated"
                className="fadeInUp"
              >
                <div className="mt-4">
                  <Logo />
                </div>
              </FadeInUpOnScroll>
            </a>
            <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
              <p className="text-justify text-[15px] text-gray-600 mt-3">
                Welcome to Labh Creation, your go-to destination for hassle-free
                event ticket booking. Discover a diverse range of exciting
                events, secure your tickets with ease, and embark on
                unforgettable experiences. Start exploring and booking your
                tickets today!
              </p>
            </FadeInUpOnScroll>
          </div>
          <div className="text-secondary mt-5">
            <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
              <h6 className="font-bold text-dark">Get our App</h6>
            </FadeInUpOnScroll>
            <hr className="border-dashed my-4" />
            <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
              <p className="text-justify text-[15px] text-gray-600">
                Enhance your event experience with our user-friendly mobile app.
                Seamlessly browse, book, and access tickets on-the-go. Download
                now and unlock convenience at your fingertips!
              </p>
            </FadeInUpOnScroll>
          </div>
          <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
            <div className="text-secondary mt-5">
              <a target="_blank" className="text-secondary" href="/help">
                <h6 className="font-bold text-dark">Contact Us</h6>
              </a>
              <hr className="border-dashed my-4" />
              <p className="text-justify mb-2 text-gray-600">
                Need help? Email us anytime for prompt assistance.
              </p>
              <p className="mb-2 flex flex-wrap mt-4">
                E-mail on:{" "}
                <a
                  href="mailto:labhcreation@gmail.com"
                  className="text-red-500"
                >
                  labhcreation@gmail.com
                </a>
              </p>
              <div className="flex justify-start items-center gap-3 mt-5">
                <a
                  href="https://www.instagram.com/labhcreation.in/"
                  className="text-red-500"
                >
                  <img
                    src={instagram}
                    alt=""
                    className="cursor-pointer w-6 h-6"
                  />
                </a>
                <a
                  href="https://www.facebook.com/labhcreation.in"
                  className="text-red-500"
                >
                  <img src={facebook} alt="" className="cursor-pointer" />
                </a>
                <a
                  href="https://wa.me/9979411999" 
                  className="text-red-500"
                  target="_blank" 
                  rel="noopener noreferrer" 
                >
                  <img src={whatsapp} alt="" className="cursor-pointer" />
                </a>
              </div>
              <div>
                <ul className="flex justify-center md:justify-start items-center text-secondary mb-0 mt-3 list-none">
                  <Link to="/help">
                    <li className="pr-1">
                      <a
                        target="_blank"
                        className="text-secondary cursor-pointer"
                        href="/help"
                      >
                        Help
                      </a>
                    </li>
                  </Link>
                  <Link to="/privacy">
                    <li className="border-l px-2">
                      <a
                        target="_blank"
                        className="text-secondary cursor-pointer"
                        href="/privacy"
                      >
                        Privacy
                      </a>
                    </li>
                  </Link>
                  <Link to="/terms">
                    <li className="border-l px-2">
                      <a
                        target="_blank"
                        className="text-secondary cursor-pointer"
                        href="/terms"
                      >
                        Terms
                      </a>
                    </li>
                  </Link>
                  <Link to="/refunds">
                    <li className="border-l px-2">
                      <a
                        target="_blank"
                        className="text-secondary cursor-pointer"
                        href="/refunds-cancellations"
                      >
                        Refunds
                      </a>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </FadeInUpOnScroll>
          <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
            <div className="text-secondary mt-5">
              <a target="_blank" className="text-secondary" href="/help">
                <h6 className="font-bold text-dark">Accept Payments</h6>
              </a>
              <hr className="border-dashed my-4" />
              <div className="grid grid-cols-12 gap-5">
                {paymentLogo?.map((value) => {
                  return (
                    <div className="col-span-4 flex justify-center items-center">
                      <img
                        src={value}
                        alt=""
                        className=" h-16 w-16 object-contain"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </FadeInUpOnScroll>
        </div>
      </div>
      <hr className="border-dashed my-4" />
      <div className="mx-auto p-4">
        <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 text-center">
            <p className="text-gray-600">
              © 2024 Copyright labhcreation.com | All Rights Reserved
            </p>
            <p className="text-gray-600">Powered By : Jstechno Pvt. Ltd.</p>
          </div>
        </FadeInUpOnScroll>
      </div>
    </>
  );
};

export default Footer;
