import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventData } from '../../data/data'; // Import your EventData

interface EventState {
  data: typeof EventData;
  activeIndex: number;
}

const initialState: EventState = {
  data: EventData,
  activeIndex: 0,
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setActiveIndex(state, action: PayloadAction<number>) {
      state.activeIndex = action.payload;
    },
    nextIndex(state) {
      state.activeIndex = (state.activeIndex + 1) % state.data.length;
    },
    prevIndex(state) {
      state.activeIndex =
        state.activeIndex === 0 ? state.data.length - 1 : state.activeIndex - 1;
    },

  },
});

export const { setActiveIndex, nextIndex, prevIndex, } = eventSlice.actions;

export default eventSlice.reducer;
export {};
