// src/redux/slices/icardFormSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FormData {
  fullName: string;
  phone: string;
  garbaClass: string;
  area: string;
  aadharCardFile: File | null;
  photoFile: File | null;
  acceptRules:boolean;
}

const initialState: FormData = {
  fullName: '',
  phone: '',
  garbaClass: '',
  area: '',
  aadharCardFile: null,
  photoFile: null,
  acceptRules:false,
};

const icardFormSlice = createSlice({
  name: 'icardForm',
  initialState,
  reducers: {
    updateFormData: (state, action: PayloadAction<Partial<FormData>>) => {
      return { ...state, ...action.payload };
    },
    resetForm: () => initialState,
  },
});

export const { updateFormData, resetForm } = icardFormSlice.actions;

export default icardFormSlice.reducer;
