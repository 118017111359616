import React from 'react';

interface OurProductCollectionProps {}


const OurProductCollection: React.FC<OurProductCollectionProps> = () => {
  return (
    <div>
      {/* <ProductDetailPage/> */}
      <h1>No data Available</h1>
    </div>
      
  )
  
}

export default OurProductCollection;
