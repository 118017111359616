import React from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import blogCamara from "../../images/blogCamara.png"
import { Helmet } from "react-helmet-async";
// import { _getImgRd, _getPersonNameRd, _getTitleRd } from "contains/fakeData";
import blogTab from "../../images/blogTab.png";
import SubscribeCamara from "components/SubscribeCamara";

const BlogSingle1 = () => {
  const renderHeader = () => {
    return (
      <>
  
     <header className="container rounded-xl">
  
       <div className="max-w-screen-md mx-auto space-y-5">
         {/* <Badge href="##" color="purple" name="Traveler" /> */}
         <div className="w-full text-left mt-6">
              <small className="text-blue-500">Dome Camara</small>
              <h1
                className="text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                title="Quiet ingenuity: 120,000 lunches and counting"
              >
                How to Choose the Right CCTV Camera for Your Business
              </h1>
         </div>
         
        
      
       </div>
     </header>
     
      </>

    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-invert"
      >
        <p>
        Helping everyone live happier, healthier lives at home through their kitchen. Kitchn is a daily food magazine on the Web celebrating life in the kitchen through home cooking and kitchen intelligence.
        </p>
        <p>
        We've reviewed and ranked all of the best smartwatches on the market right now, and we've made a definitive list of the top 10 devices you can buy today. One of the 10 picks below may just be your perfect next smartwatch.
        </p>
        <p>
        Those top-end wearables span from the Apple Watch to Fitbits, Garmin watches to Tizen-sporting Samsung watches. There's also Wear OS which is Google's own wearable operating system in the vein of Apple's watchOS - you’ll see it show up in a lot of these devices.
        </p>
        <h3>Lorem ipsum dolor sit amet cons</h3>
        <p>
          Throughout our review process, we look at the design, features, battery life, spec, price and more for each smartwatch, rank it against the competition and enter it into the list you'll find below.
        </p>
        <figure>
          <div className="flex gap-4">
              <img
                src={blogTab}
                alt="nc blog"
                className="rounded-2xl h-40 lg:h-80"
              />
              <img
                src={blogTab}
                alt="nc blog"
                className="rounded-2xl h-40 lg:h-80"
              />
          </div>
          
          <p>
            Tortor, lobortis semper viverra ac, molestie tortor laoreet amet euismod et diam quis aliquam consequat porttitor integer a nisl, in faucibus nunc et aenean turpis dui dignissim nec scelerisque ullamcorper eu neque, augue quam quis lacus pretium eros est amet turpis nunc in turpis massa et eget facilisis ante molestie penatibus dolor volutpat, porta
            pellentesque scelerisque at ornare dui tincidunt cras feugiat tempor lectus
          </p>
        </figure>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet id enim, libero sit. Est donec lobortis cursus amet, cras elementum libero convallis feugiat. Nulla faucibus facilisi tincidunt a arcu, sem donec sed sed. Tincidunt morbi scelerisque lectus non. At leo mauris, vel augue. Facilisi diam consequat amet, commodo lorem nisl, odio malesuada
            cras. Tempus lectus sed libero viverra ut. Facilisi rhoncus elit sit sit.
        </p>
    
      </div>
    );
  };

  return (
    <div className="nc-PageSingle">
         <div className="container mb-5">
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link to={"/#"} className="text-blue-500">
              Home
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <Link to={"/#"} className="text-blue-500">
              Blog
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">Blog Details</span>
          </div>
       </div>
       <hr/>
      <Helmet>
        <title>Single Blog</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src={blogCamara}
      />

      <div className="nc-SingleContent mb-5 container space-y-10">
        {renderContent()}
        <div>
          <SubscribeCamara/>
        </div>
      </div>
      
    </div>
  );
};

export default BlogSingle1;
