import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
// import { RootState, AppDispatch } from "../../redux/store";
import { updateFormData } from "../../redux/IcardData/icardFormSlice";
import FadeInUpOnScroll from "../../components/Animation/FadeInUpOnScroll";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SweetToast } from "utils/sweetAlert";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IcardRules from "./IcardRules";
import addressData from "../../components/Address.json";
import { GarbaFormAddList, UploadFile } from "containers/store";

interface FormValues {
  fullName: string;
  phone: string;
  area: string;
  aadharCardFile: any;
  photoFile: any;
  acceptTerms: boolean;
}

// function isFile(value: unknown): value is File {
//   return value instanceof File;
// }

const IcardForm: React.FC = () => {
 
  const dispatch = useDispatch();

  const [showRules, setShowRules] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State to track the search query
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to track dropdown visibility
  const [selectedFromDropdown, setSelectedFromDropdown] = useState(false);
  const [isLoadingAadhar, setIsLoadingAadhar] = useState(false);
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false);
  const [aadharPreview, setAadharPreview] = useState<string | null>(null); // Store Aadhar image preview URL
  const [aadharCard, setAadharCard] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState<string | null>(null); // Store Photo image preview URL

  const validationSchema = Yup.object({
    fullName: Yup.string()
      .required("Full Name is required")
      .min(2, "Full Name must be at least 2 characters long"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Phone number is not valid"),
    area: Yup.string().required("Area is required"),
    // aadharCardFile: Yup.mixed()
    //   .required("You need to upload your Aadhar Card")
    //   .test("fileSize", "File size is too large", function (value) {
    //     if (!isFile(value)) return true; // No file uploaded or not a File object
    //     return value.size <= 1024 * 1024; // 1MB limit
    //   })
    //   .test("fileType", "Unsupported file format", function (value) {
    //     if (!isFile(value)) return true; // No file uploaded or not a File object
    //     return [
    //       "image/jpg",
    //       "image/jpeg",
    //       "image/png",
    //       "application/pdf",
    //     ].includes(value.type);
    //   }),
    // photoFile: Yup.mixed()
    //   .required("You need to upload your Photo")

    //   .test(
    //     "fileSize",
    //     "File size is too large. Maximum size is 2MB",
    //     (value: any) => value && value.size <= 2 * 1024 * 1024 // 2MB
    //   )
    //   .test(
    //     "fileType",
    //     "Unsupported file format. Only PNG, JPEG, and PDF are allowed.",
    //     (value: any) =>
    //       value &&
    //       ["image/jpeg", "image/png", "application/pdf"].includes(value.type)
    //   ),
    acceptTerms: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
  });

  const initialValues: FormValues = {
    fullName: "",
    phone: "",
    area: "",
    aadharCardFile: null,
    photoFile: null,
    acceptTerms: false,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: FormValues, { resetForm }) => {
      const payLoad = {
        ...values,
        photo: photo,
        aadhar_card: aadharCard,
      };
      console.log("I Card Payload:", payLoad);
      if (!photo) {
        SweetToast.error("Photo Filed is Required");
      } else if (!aadharCard) {
        SweetToast.error("AadharCard Filed is Required");
      } else {
        dispatch(GarbaFormAddList(payLoad));
        SweetToast.success("Submit successful");
        resetForm();
        // navigate("/garba-class-form-list");
      }
      // SweetToast.success(
      //   `<div style="font-size: 12px; line-height: 1.4;">
      //     Data submit Successful!<br>
      //     I-Card Received Date: 30/09/2024 & Time: 1:00 pm to 4:00 pm
      //   </div>`
      // );
      // resetForm();
    },
    validateOnChange: false,
    validateOnBlur: true,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;
    dispatch(updateFormData({ [name]: files ? files[0] : value }));
    formik.handleChange(e);
    setSelectedFromDropdown(false);
    // Check input length to control dropdown visibility
    // if (value.length > 0 && filteredAddressData.length > 0) {
    //   setIsDropdownOpen(true);
    // } else {
    //   setIsDropdownOpen(false);
    // }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    formik.setFieldValue(name, checked);
    setShowRules(checked);
  };

  // Filtered address data based on the search query
  const filteredAddressData = addressData.filter((address) =>
    address.Location.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Close dropdown when clicking outside
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  
  useEffect(() => {
    if (!selectedFromDropdown) {
      formik.setFieldValue("area", searchQuery); // Update with custom value
    }
  }, [searchQuery, selectedFromDropdown, formik]);

  const handleSelectArea = (location: string, address: any) => {
    const fullAddress = `${address.Location}, ${address.District}, ${address.State}, ${address["Pin Code"]}`;

    formik.setFieldValue("area", fullAddress); // Update with full address
    setSearchQuery(fullAddress); // Display full address in the search bar
    setIsDropdownOpen(false);
    setSelectedFromDropdown(true);

    // Log the full address in the console
    console.log(`Full Address: ${fullAddress}`);
  };



  return (
    <div className="mx-auto p-6 max-w-4xl bg-white rounded-lg shadow-lg">
      <form onSubmit={formik.handleSubmit} className="space-y-6">
        <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
          <div className="text-center">
            <h1 className="text-2xl font-bold text-blue-600">
              I-Card Registration Form
            </h1>
            <p className="text-sm text-gray-600 mt-2">
              Please fill in the details below to register for your I-Card.
            </p>
          </div>
        </FadeInUpOnScroll>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
            <div className="space-y-4">
              <div className="flex flex-col">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Full Name <small className="text-red-400">*</small>
                </label>
                <input
                  type="text"
                  name="fullName"
                  onChange={handleInputChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullName}
                  className={`block w-full border rounded-md py-2 px-3 text-sm ${
                    formik.touched.fullName && formik.errors.fullName
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                />
                {formik.touched.fullName && formik.errors.fullName ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.fullName}
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Phone <small className="text-red-400">*</small>
                </label>
                <input
                  type="text"
                  name="phone"
                  onChange={handleInputChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  className={`block w-full border rounded-md py-2 px-3 text-sm ${
                    formik.touched.phone && formik.errors.phone
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.phone}
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Area <small className="text-red-400">*</small>
                </label>
                {/* Search Input */}
                <input
                  type="text"
                  placeholder="Search area..."
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setIsDropdownOpen(true); // Open dropdown when typing
                  }}
                  className="mb-2 block w-full border rounded-md py-2 px-3 text-sm border-gray-300"
                />

                {/* Dropdown with filtered address data */}
                {isDropdownOpen && filteredAddressData.length > 0 && (
                  <div
                    ref={dropdownRef}
                    className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-20 max-h-40 overflow-auto shadow-lg"
                  >
                    {filteredAddressData.map((address, index) => (
                      <div
                        key={index}
                        className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => {
                          handleSelectArea(address.Location, address);
                          setSelectedFromDropdown(true);
                        }} // Pass address object
                      >
                        {`${address.Location}, ${address.District}, ${address.State}, Pin: ${address["Pin Code"]}`}
                      </div>
                    ))}
                  </div>
                )}

                {formik.touched.area && formik.errors.area ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.area}
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col space-y-6">
                <div className="flex flex-wrap gap-6">
                  <div className="flex-1 min-w-[200px]">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Upload Your Aadhar Card{" "}
                      <small className="text-red-400">*</small>
                    </label>
                    <div className="flex flex-wrap justify-start items-center">
                      <div>
                        <input
                          type="file"
                          name="aadharCardFile"
                          onChange={async (event) => {
                            const file = event.currentTarget.files?.[0];
                            // formik.setFieldValue("aadharCardFile", file);
                            if (file) {
                              const payLoadForFile = {
                                file_upload: file,
                                type: "Events",
                                module_name: "events",
                              };
                              if (file) {
                                setIsLoadingAadhar(true);
                                dispatch(UploadFile(payLoadForFile))
                                  .then((data: any) => {
                                    setAadharPreview(
                                      data?.payload?.uploadFileData
                                        ?.user_document?.attachment ?? ""
                                    );
                                    setAadharCard(
                                      data?.payload?.uploadFileData
                                        ?.user_document?.attachment ?? ""
                                    );
                                    console.log(
                                      data?.payload?.uploadFileData
                                        ?.user_document?.attachment,
                                      "data"
                                    );
                                    setIsLoadingAadhar(false);
                                  })
                                  .catch(() => {
                                    setIsLoadingAadhar(false);
                                  });
                              }
                              // setIsLoadingAadhar(true);
                              await formik.setFieldValue(
                                "aadharCardFile",
                                file
                              );
                              // setIsLoadingAadhar(false);
                            }
                          }}
                        />

                        {/* {formik.touched.aadharCardFile &&
                        typeof formik.errors.aadharCardFile === "string" ? (
                          <p className="text-red-500 text-sm">
                            {formik.errors.aadharCardFile}
                          </p>
                        ) : null} */}
                      </div>
                      <div>
                        {isLoadingAadhar ? (
                          <div className="mt-2 text-blue-600 text-sm">
                            Uploading...
                          </div>
                        ) : (
                          <div>
                            {aadharPreview && (
                              <div className="mt-2">
                                <img
                                  src={aadharPreview}
                                  alt="Uploaded Aadhar card preview"
                                  className="h-10 w-10 object-cover rounded-lg" // Adjust dimensions as necessary
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex-1 min-w-[200px]">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Upload Your Photo{" "}
                      <small className="text-red-400">*</small>
                    </label>
                    <div className="flex flex-wrap justify-start items-center">
                      <div>
                        <input
                          type="file"
                          name="photoFile"
                          onChange={(event) => {
                            const file = event.currentTarget.files?.[0];
                            if (file) {
                              const payLoadForFile = {
                                file_upload: file,
                                type: "Events",
                                module_name: "events",
                              };

                              if (file) {
                                setIsLoadingPhoto(true);
                                dispatch(UploadFile(payLoadForFile))
                                  .then((data: any) => {
                                    setPhotoPreview(
                                      data?.payload?.uploadFileData
                                        ?.user_document?.attachment ?? ""
                                    );
                                    setPhoto(
                                      data?.payload?.uploadFileData
                                        ?.user_document?.attachment ?? ""
                                    );
                                    console.log(
                                      data?.payload?.uploadFileData
                                        ?.user_document?.attachment,
                                      "data"
                                    );
                                    setIsLoadingPhoto(false);
                                  })
                                  .catch(() => {
                                    setIsLoadingPhoto(false);
                                  });
                                // dispatch(UploadFile(payLoadForFile)).then(
                                // )
                              }
                              formik.setFieldValue("photoFile", file);
                            }
                          }}
                        />
                        {/* {formik.touched.photoFile &&
                        typeof formik.errors.photoFile === "string" ? (
                          <p className="text-red-500 text-sm">
                            {formik.errors.photoFile}
                          </p>
                        ) : null} */}
                      </div>
                      <div>
                        {isLoadingPhoto ? (
                          <div className="mt-2 text-blue-600 text-sm">
                            Uploading...
                          </div>
                        ) : (
                          <div>
                            {photoPreview && (
                              <div className="mt-2">
                                <img
                                  src={photoPreview}
                                  alt="Uploaded Aadhar card preview"
                                  className="h-10 w-10 object-cover rounded-lg" // Adjust dimensions as necessary
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FadeInUpOnScroll>

          <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
            <div className="mt-4 items-center">
              <input
                type="checkbox"
                id="accept-terms"
                name="acceptTerms"
                checked={formik.values.acceptTerms}
                onChange={handleCheckboxChange}
                onBlur={formik.handleBlur}
                className={`mr-2 h-4 w-4 text-blue-600 border-gray-300 rounded ${
                  formik.touched.acceptTerms && formik.errors.acceptTerms
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
              />
              <label htmlFor="accept-terms" className="text-sm md:text-base">
                I accept the terms and conditions
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.acceptTerms as string}
                </div>
              ) : null}
            </div>
            {showRules && (
              <div className="mt-4">
                <IcardRules />
              </div>
            )}

            <div className="flex flex-col bg-gray-50 rounded-lg shadow-md p-6 mt-2 space-y-4">
              <div className="text-center">
                <strong className="text-xl font-bold text-blue-600">
                  Rs. 1500
                </strong>
              </div>
              <div className="text-center text-gray-700">
                <p className="text-base">Parking Free (First cum First)</p>
              </div>
              <div className="border-t border-gray-200 pt-3 text-center text-gray-600 text-sm">
                <p>Includes all necessary charges</p>
              </div>
              <div className="flex items-center justify-center">
                <button
                  disabled={isLoadingAadhar || isLoadingPhoto}
                  type="submit"
                  className={`px-6 py-3 ${
                    isLoadingAadhar || isLoadingPhoto
                      ? "bg-slate-500"
                      : "bg-blue-600"
                  }  text-white font-semibold rounded-lg shadow-md transition-transform transform hover:scale-105 hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300`}
                >
                  {isLoadingAadhar || isLoadingPhoto
                    ? "Please Wait ..."
                    : " Get I-Card"}
                </button>
              </div>
            </div>
          </FadeInUpOnScroll>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default IcardForm;
