import React from "react";

const Privacy = () => {
  return (
    <>
      <div className="container bg-gray-100 mt-2">
        <div className="py-4">
          <div className="text-center p-4 bg-gradient-to-b from-blue-900 to-teal-400 text-white mb-1 rounded-lg shadow-md">
            <h3 className="text-3xl md:text-4xl font-light mb-1">Policy</h3>
            <p>You trust, and we protect your privacy.</p>
          </div>
        </div>
        <div className="container mx-auto px-4 pb-4">
          <div className="bg-white rounded-lg shadow-lg p-6 md:p-8">
            <p className="text-gray-700 mb-6 text-justify">
              This Policy explains how Eka-event collects, uses, and protects
              personal information collected from users of the Eka-Event
              platform. By accessing or using our services, you acknowledge and
              agree to the practices described in this Policy. If you do not
              agree with any part of this Policy, please refrain from using our
              services.
            </p>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">
                Information We Collect
              </h5>
              <ol className="list-inside list-disc text-gray-600 space-y-4">
                <li className="text-justify">
                  <strong className="font-medium">Personal Information:</strong>{" "}
                  We may collect personal information, such as your name,
                  contact details, email address, and payment information when
                  you register an account, use our services, or interact with
                  the our Platform.
                </li>
                <li className="text-justify">
                  <strong className="font-medium">Usage Information:</strong>{" "}
                  You agree to use our Platform in compliance with all
                  applicable laws and regulations. You must not engage in any
                  unauthorized or prohibited activities, including but not
                  limited to.
                </li>
                <li className="text-justify">
                  <strong className="font-medium">
                    Cookies and Tracking Technologies:
                  </strong>{" "}
                  We may use cookies and similar tracking technologies to
                  enhance your user experience, personalize content, analyze
                  trends, and gather information about user interactions with
                  the Platform.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">
                How We Use Collected Information
              </h5>
              <ol className="list-inside list-disc text-gray-600 space-y-4">
                <li className="text-justify">
                  <strong className="font-medium">Service Delivery:</strong> We
                  use the collected information to provide, maintain, and
                  improve our services, including facilitating connections
                  between users and service providers, processing payments, and
                  responding to inquiries or requests.
                </li>
                <li className="text-justify">
                  <strong className="font-medium">Communication:</strong> We may
                  use your contact information to communicate with you about our
                  services, updates, promotions, or relevant information. You
                  can manage your communication preferences through your account
                  settings or by following the instructions provided in the
                  communication.
                </li>
                <li className="text-justify">
                  <strong className="font-medium">
                    Analytics and Personalization:
                  </strong>{" "}
                  We may analyze the collected information to understand user
                  preferences, trends, and usage patterns. This analysis allows
                  us to personalize your experience, improve our services, and
                  develop new features and offerings.
                </li>
                <li className="text-justify">
                  <strong className="font-medium">Legal Compliance:</strong> We
                  may process personal information to comply with applicable
                  laws, regulations, legal processes, or enforceable
                  governmental requests.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">
                Information Sharing and Disclosure
              </h5>
              <ol className="list-inside list-disc text-gray-600 space-y-4">
                <li className="text-justify">
                  <strong className="font-medium">Service Providers:</strong> We
                  may engage third-party service providers to perform certain
                  functions on our behalf, such as payment processing, data
                  analysis, marketing assistance, or customer support. These
                  service providers have access to personal information solely
                  for the purpose of carrying out their services and are
                  obligated to protect your information.
                </li>
                <li className="text-justify">
                  <strong className="font-medium">Business Transfers:</strong>{" "}
                  In the event of a merger, acquisition, or sale of assets, your
                  personal information may be transferred to the acquiring
                  entity or third party as part of the transaction. We will
                  notify you via email or prominent notice on the platform in
                  such circumstances.
                </li>
                <li className="text-justify">
                  <strong className="font-medium">Legal Requirements:</strong>{" "}
                  We may disclose personal information if required to do so by
                  law or in good faith belief that such action is necessary to
                  comply with legal obligations, protect our rights or
                  interests, investigate fraud, or respond to valid requests
                  from public authorities.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">Data Security</h5>
              <ol className="list-inside list-disc text-gray-600 space-y-4">
                <li className="text-justify">
                  <strong className="font-medium">Security Measures:</strong> We
                  employ industry-standard security measures to protect your
                  personal information against unauthorized access, disclosure,
                  alteration, or destruction. However, no method of transmission
                  over the internet or electronic storage is entirely secure,
                  and we cannot guarantee absolute security.
                </li>
                <li className="text-justify">
                  <strong className="font-medium">Account Security:</strong> It
                  is your responsibility to keep your account credentials
                  confidential and to notify us immediately of any unauthorized
                  access or use of your account.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">Data Retention</h5>
              <ol className="list-inside list-disc text-gray-600 space-y-4">
                <li className="text-justify">
                  We will retain your personal information for as long as
                  necessary to fulfill the purposes outlined in this Policy,
                  unless a longer retention period is required or permitted by
                  law.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">
                Your Rights and Choices
              </h5>
              <ol className="list-inside list-disc text-gray-600 space-y-4">
                <li className="text-justify">
                  <strong className="font-medium">Access and Update:</strong>{" "}
                  You may access and update your personal information through
                  your account settings. If you need further assistance, please
                  contact our customer support.
                </li>
                <li className="text-justify">
                  <strong className="font-medium">
                    Marketing Communications:
                  </strong>{" "}
                  You can opt-out of receiving promotional communications from
                  us by following the instructions provided in the communication
                  or by contacting us directly.
                </li>
              </ol>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">
                Third-Party Links and Services
              </h5>
              <p className="text-gray-600 mb-2 text-justify">
                The Platform may contain links to third-party websites or
                services. This Policy does not apply to such third-party
                websites or services. We encourage you to review the policies of
                those third parties before interacting with their services.
              </p>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">Children Privacy</h5>
              <p className="text-gray-600 mb-2 text-justify">
                The Platform are not intended for individuals under the age of
                18. We do not knowingly collect personal information from
                children. If you become aware that your child has provided us
                with personal information, please contact us, and we will take
                steps to remove the information from our systems.
              </p>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">
                Changes to this Policy
              </h5>
              <p className="text-gray-600 mb-2 text-justify">
                We reserve the right to modify or update this Policy at any
                time. We will notify you of any material changes by posting the
                updated Policy on the Plaform or by sending you an email. Your
                continued use of our services after any modifications to the
                Policy constitutes your acceptance of the revised Policy.
              </p>
              <p className="text-gray-600 mb-0 text-justify">
                We may seek location permission for tailored event
                recommendations, always respecting your privacy by not
                disclosing this information externally. Our request to read
                messages for OTP autofill is optional, convenient, and only used
                within its purpose. Rigorous technical and organizational
                measures safeguard your data, and we comply strictly with data
                protection regulations, ensuring it's not shared with third
                parties unless required by law.
              </p>
            </div>

            <div className="mb-6">
              <h5 className="text-xl font-semibold mb-3">Contact Us</h5>
              <p className="text-gray-600 mb-0 text-justify">
                If you have any questions, concerns, or requests regarding this
                Privacy Policy, please contact us at - E-mail on:
                labhcreation@gmail.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
