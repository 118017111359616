// store.ts
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import countReducer from "./Count/countSlice";
import eventReducer from "./EventSlice/eventSlice";
import CheckOut from "./checkout/checkoutSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import ContainerData from "./Auth";
import ContainerDataEvent from "../containers/store/index";
import cartReducer from "./Cart/cartSlice"; // Import cartReducer
import icardFormReducer from "./IcardData/icardFormSlice";

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  ContainerData: ContainerData,
  ContainerDataEvent: ContainerDataEvent,
  cart: cartReducer, // Include cartReducer in combineReducers
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: {
    persistedReducer,
    count: countReducer,
    event: eventReducer,
    CheckOut: CheckOut,
    icardForm: icardFormReducer,
  },
});

export default store;
