import React from "react";
import Abhijit from "../../Assets/Artist/Abhijit.png";
import dimple from "../../Assets/Artist/dimpal.png";
import himanshu from "../../Assets/Artist/himanshu.png";
import jitinAmit from "../../Assets/Artist/jitin-Amit.png";
import madhavioza from "../../Assets/Artist/madhavi oza.png";
import mayurisoni from "../../Assets/Artist/mayuri soni.png";
import payal from "../../Assets/Artist/payal.png";
import samirraval from "../../Assets/Artist/samir raval.png";
import shyamghadvi from "../../Assets/Artist/shyam ghadvi.png";
import varshakulkarni from "../../Assets/Artist/varsha-kulkarni.png";
import KausikOutdoors from "../../Assets/kausikOutdoors.jpg";
import MyFm from "../../Assets/RadioPartner.jpg";
import Bookmyshow from "../../Assets/BookMyShow.jpg";

interface EventDetails {
  date: string;
  artist: string;
  artistImage: string;
}

const events: EventDetails[] = [
  {
    date: "03 Oct",
    artist: "Varsha Kulkarni",
    artistImage: varshakulkarni,
  },
  { date: "04 Oct", artist: "Jitin-Amit", artistImage: jitinAmit },
  { date: "05 Oct", artist: "Mayuri Soni", artistImage: mayurisoni },
  {
    date: "06 Oct",
    artist: "Shyam Ghadvi",
    artistImage: shyamghadvi,
  },
  { date: "07 Oct", artist: "Madhavi Oza", artistImage: madhavioza },
  {
    date: "08 Oct",
    artist: "Himanshu Trivedi",
    artistImage: himanshu,
  },
  { date: "09 Oct", artist: "Samir Raval", artistImage: samirraval },
  { date: "10 Oct", artist: "Dimple Pancholi", artistImage: dimple },
  { date: "11 Oct", artist: "Payal Vakhariya", artistImage: payal },
  { date: "11 Oct", artist: "Abhijit Rao", artistImage: Abhijit },
];

const Orchesta: React.FC = () => {
  return (
    <div className="container mx-auto p-4">
      {/* Header Image */}
      <div className="p-4 bg-gradient-to-b from-blue-900 to-teal-400 text-white mb-1 rounded-lg shadow-md">
        <h1 className="text-sm md:text-2xl font-bold text-center">
          Orchesta List for 03 Oct to 11 Oct, 2024
        </h1>
      </div>

      {/* Artist Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5 gap-6 mt-4">
        {events.map((event, index) => (
          <div key={index} className="flex flex-col items-center text-center">
            <div className="w-10 h-10 sm:w-25 sm:h-30 md:w-25 md:h-30 lg:w-25 lg:h-30 bg-gray-200 rounded-full overflow-hidden shadow-md">
              <img
                src={event.artistImage}
                alt={event.artist}
                className="w-full h-full object-cover"
              />
            </div>
            <h3 className="mt-4 text-sm font-semibold">{event.artist}</h3>
            <p className="text-sm text-gray-500">{event.date}</p>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3  gap-6 mt-4">
        <div className="flex flex-col justify-center items-center text-center shadow-md p-4 rounded-lg">
          <img
            src={KausikOutdoors}
            alt="Outdoors Partner"
            className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 object-contain mb-2"
          />
          <h3 className="text-sm font-semibold">Outdoors Partner</h3>
        </div>
        <div className="flex flex-col justify-center items-center text-center shadow-md p-4 rounded-lg">
          <img
            src={MyFm}
            alt="Radio Partner"
            className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 object-contain mb-2"
          />
          <h3 className="text-sm font-semibold">Radio Partner</h3>
        </div>
        <div className="flex flex-col justify-center items-center text-center shadow-md p-4 rounded-lg">
          <img
            src={Bookmyshow}
            alt="Online Ticket Available"
            className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 object-contain mb-2"
          />
          <h3 className="text-sm font-semibold">Online Ticket Available</h3>
        </div>
      </div>
    </div>
  );
};

export default Orchesta;
