import React, { FC } from "react";
import cctv from "images/cctv.png"

export interface SubscribeCamaraProps {
  className?: string;
  itemClassName?: string;

}

const SubscribeCamara: FC<SubscribeCamaraProps> = () => {
  

  return (
   <>
      <div className="lg:grid lg:grid-cols-12 bg-[#BBDEFF] h-auto w-full lg:p-20 p-10 rounded-lg">
        <div className="col-span-8">
            <h2 className="font-bold text-3xl">
                Ensuring your safety with state-of-the-art CCTV camera solutions.
            </h2>
        </div>
        <div className="lg:col-span-4">
          <img src={cctv} alt="CCTV" className="size-60 w-80"/>
        </div>
        <div className="relative w-80 mt-4">
            <input
              type="text"
              className="w-full py-4 px-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-500"
              placeholder="Enter text..."
            />
            <button
              type="button"
              className="absolute inset-y-0 rounded-full w-40 right-0 px-4 py-2 bg-primary-700 text-white font-semibold"
            >
              Subscribe
            </button>
        </div>
      </div>
   </>
  );
};

export default SubscribeCamara;
