import React from "react";

const Refunds = () => {
  return (
    <>
      <div className="container bg-gray-100 mt-2">
        <div className="py-4">
          <div className="text-center p-4 bg-gradient-to-b from-blue-900 to-teal-400 text-white mb-1 rounded-lg shadow-md">
            <h3 className="text-2xl md:text-4xl font-light mb-1">
              Refunds and Cancellations
            </h3>
            <p className="text-justify">
              True professionals not only follows but loves the processes,
              policies and principles set by their profession.
            </p>
          </div>
        </div>
        <div className="py-2 px-2 bg-gray-100 container mx-auto">
          <div className="bg-white shadow-lg rounded-lg p-6 border border-gray-200">
            <div className="mb-6">
              <h5 className="text-2xl font-semibold text-gray-800 mb-3">
                Refunds and Cancellations
              </h5>
              <ol className="list-decimal list-inside space-y-4 text-gray-700">
                <li className="text-justify">
                  <strong className="font-semibold">Service Fees:</strong> All
                  fees and charges paid to the Company for services rendered are
                  non-refundable unless otherwise explicitly stated in a
                  separate agreement or as required by applicable law.
                </li>
                <li className="text-justify">
                  <strong className="font-semibold">Cancellations:</strong> Once
                  a service is booked or purchased, cancellation requests will
                  not be accepted, and refunds will not be issued.
                </li>
                <li className="text-justify">
                  <strong className="font-semibold">No Partial Refunds:</strong>{" "}
                  The Company does not provide partial refunds for any portion
                  of a service that was not utilized or for any unused features
                  or components of a service.
                </li>
                <li className="text-justify">
                  <strong className="font-semibold">
                    Cancellation Policy:
                  </strong>{" "}
                  If you need to cancel a service or booking, you must adhere to
                  the cancellation policy stated during the purchase or booking
                  process. Failure to comply with the specified cancellation
                  policy may result in the forfeiture of any applicable refund.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Refunds;
