import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Breadcrumb from "./Breadcrumb";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GarbaClassList } from "containers/store";
import PaginationPage from "shared/Pagination/PaginationPage";
import {
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import Swal from "sweetalert2";
import { LoadingView } from "./LoadingView";

const GarbaClassesDetail = () => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10); // Initially set to 10
  const getGarbaClassList = useSelector(
    (state: any) => state.persistedReducer.ContainerDataEvent.garbaClassList
  );
  const isLoadingGetGarbaClassList = useSelector(
    (state: any) =>
      state.persistedReducer.ContainerDataEvent.isLoadingGarbaClassList
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const addClick = () => {
    navigate("/garba-class-form-add");
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    const newRowsPerPage = parseInt(event.target.value as string, 10); // Cast value to string first and then parse to number
    setRowsPerPage(newRowsPerPage);
    setPage(1); // Reset to the first page when changing rows per page

    const payload = {
      page: 1,
      per_page: newRowsPerPage,
    };

    dispatch(GarbaClassList(payload));
  };

  const handleNext = () => {
    setPage(page + 1);
  };

  const handlePrev = () => {
    setPage(page - 1);
  };

  const handleSelectedPage = (data: any) => {
    setPage(data);
  };

  const onEdit = (row: any) => {
    navigate(`/garba-class-form-edit/${row?.uuid}`);
  };

  // const onDelete = (row: any) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       dispatch(GarbaFormDeleteByID(row?.id)).then(() => {
  //         const payload = {
  //           page: 1,
  //           per_page: rowsPerPage,
  //         };
  //         dispatch(GarbaClassList(payload));
  //         Swal.fire({
  //           title: "Deleted!",
  //           text: "Your file has been deleted.",
  //           icon: "success",
  //         });
  //       });
  //     }
  //   });
  // };

  useEffect(() => {
    const payload = {
      page: page,
      per_page: rowsPerPage,
    };
    dispatch(GarbaClassList(payload));
  }, [dispatch, page, rowsPerPage]);

  // if (isLoadingGetGarbaClassList) {
  //   return <LoadingView />;
  // }

  return (
    <>
      <div className="container relative">
        {isLoadingGetGarbaClassList && <LoadingView />}
        <div className="flex justify-between items-center mb-4 mt-4">
          <Breadcrumb
            items={[
              { label: "Back", path: "/garba-class-form-list" },
              { label: "Garba-class-List", path: "/garba-class-form-add" },
              { label: "", path: "#" },
            ]}
          />
          <button
            onClick={addClick}
            className="bg-blue-500 text-white font-medium py-2 px-4 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Add
          </button>
        </div>

        {getGarbaClassList?.data?.length ? (
          <>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {getGarbaClassList?.columns?.map((column: any) => (
                        <TableCell key={column.key} align="left">
                          {column.label}
                        </TableCell>
                      ))}
                      {/* Sticky Actions column */}
                      <TableCell
                        align="center"
                        sx={{
                          position: "sticky",
                          right: 0,
                          backgroundColor: "#fff",
                          zIndex: 50,
                          width: "100px", // Set a fixed width for the actions column
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getGarbaClassList?.data?.map((row: any, index: any) => (
                      <TableRow hover tabIndex={-1} key={index}>
                        {getGarbaClassList?.columns?.map((column: any) => {
                          console.log(column, "column");
                          return (
                            <>
                              <TableCell key={column.key} align="left">
                                {column.type === "image" ? (
                                  <img
                                    src={row[column.key]}
                                    alt={column.label}
                                    className="object-cover w-12 h-12"
                                  />
                                ) : (
                                  row[column.key]
                                )}
                              </TableCell>
                            </>
                          );
                        })}
                        {/* Sticky Actions icons */}
                        <TableCell
                          align="center"
                          sx={{
                            position: "sticky",
                            right: 0,
                            background: "#fff",
                            zIndex: 1,
                          }}
                        >
                          <IconButton
                            color="primary"
                            onClick={() => onEdit(row)}
                          >
                            <EditIcon />
                          </IconButton>
                          {/* <IconButton color="error" onClick={() => onDelete(row)}>
                <DeleteIcon />
              </IconButton> */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            {/* Dropdown and Pagination Controls */}
            <div className="flex flex-wrap justify-between items-center mt-4 mb-2">
              <div>
                {/* Records per page dropdown */}
                <FormControl variant="outlined" className="w-25">
                  <InputLabel id="rows-per-page-label">page</InputLabel>
                  <Select
                    labelId="rows-per-page-label"
                    id="rows-per-page"
                    value={rowsPerPage}
                    onChange={handleChangeRowsPerPage}
                    label="Rows per page"
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                    <MenuItem value={300}>300</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <p className="text-sm text-gray-600">
                  Showing {getGarbaClassList?.meta?.from} to{" "}
                  {getGarbaClassList?.meta?.to} of{" "}
                  {getGarbaClassList?.meta?.total} entries
                </p>
              </div>
              <div className="mt-2 mx-1 sm:mx-0 md:mx-0 lg:mx-0">
                <div>
                  {/* Pagination */}
                  <PaginationPage
                    currentPage={page}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    handleSelectedPage={handleSelectedPage}
                    totalPages={getGarbaClassList?.meta?.last_page}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="text-center my-5">
            No Date Available, please add some data first by clicking the Add
            button.
          </div>
        )}
      </div>
    </>
  );
};

export default GarbaClassesDetail;
