import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import {
  nextIndex,
  prevIndex,
  setActiveIndex,
} from "../../redux/EventSlice/eventSlice";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import Next from "../../shared/NextPrev/Next";
import Prev from "../../shared/NextPrev/Prev";
import ProductEventCard from "../../components/ProductEventCard";
import useEventData from "api/Event/useEventData";

export interface SectionHero2Props {
  className?: string;
}

const SectionEventHero: FC<SectionHero2Props> = ({ className = "" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { events } = useEventData();
  // Selectors
  const eventData = useSelector((state: RootState) => state.event.data);
  const indexActive = useSelector(
    (state: RootState) => state.event.activeIndex
  );

  // Handle automatic slide change
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(nextIndex());
    }, 5000); // Change slide every 5 seconds

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [dispatch]);

  const handleClickNext = () => {
    dispatch(nextIndex());
  };

  const handleClickPrev = () => {
    dispatch(prevIndex());
  };

  const handleDotClick = (index: number) => {
    dispatch(setActiveIndex(index));
  };

  const handleButtonClick = (id: number) => {
    if (id === 2) {
      navigate(`/orchesta`);
    } else if (id === 3) {
      window.open(
        "https://www.google.com/maps/place/EKA+Club/@23.0108347,72.5966213,17z/data=!3m1!4b1!4m6!3m5!1s0x395e85cedfe1ecbb:0xe70b832deddbed49!8m2!3d23.0108347!4d72.5991962!16s%2Fg%2F11gds3vj_4?entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D",
        "_blank"
      );
    } else {
      navigate(`/product-detail/${events?.data?.[0]?.id}`);
    }
  };

  const handleImageClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    id: number
  ) => {
    if (id === 3) {
      window.open(
        "https://www.google.com/maps/place/EKA+Club/@23.0108347,72.5966213,17z/data=!3m1!4b1!4m6!3m5!1s0x395e85cedfe1ecbb:0xe70b832deddbed49!8m2!3d23.0108347!4d72.5991962!16s%2Fg%2F11gds3vj_4?entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D",
        "_blank"
      );
    } else if (id === 2) {
      navigate(`/orchesta`);
    } else {
      navigate(`/product-detail/${events?.data?.[0]?.id}`);
    }
  };

  const renderItem = (index: number) => {
    const isActive = indexActive === index;
    const item = eventData[index];
    let buttonText;
    if (item.id === 1) {
      buttonText = "Buy Ticket";
    } else if (item.id === 3) {
      buttonText = "View Location";
    } else if (item.id === 2) {
      buttonText = "View Orchesta List";
    } else {
      buttonText = "View Details";
    }

    const bgClass = `bg-item-${item.id}`;

    return (
      <div
        className={`transition-transform duration-500 ease-in-out ${
          isActive ? "translate-x-0" : "translate-x-full"
        } absolute inset-0 flex items-center justify-center`}
        key={index}
      >
        <div
          className={`relative w-full h-full flex items-center justify-center overflow-hidden event-hero-background ${bgClass}`}
        >
          <div className="absolute inset-0 bg-black bg-opacity-30 backdrop-blur-md"></div>
          <div className="hidden lg:flex flex-col justify-center items-center relative z-10 text-center text-white p-4">
            <h2 className="text-xl lg:text-2xl font-semibold mb-2">
              {item.name}
            </h2>
            <div className="mb-2">
              <span className="text-xs font-normal">
                {item.Date}
                {", "}
                {item.Time}
              </span>
            </div>
            <ButtonPrimary
              className="font-semibold border border-slate-100 rounded-lg text-sm px-5 py-2 text-white transition-transform transform hover:bg-opacity-100 hover:scale-105 hover:shadow-lg focus:outline-none focus:ring-1 focus:ring-slate-400"
              onClick={() => handleButtonClick(item.id)}
            >
              {buttonText}
            </ButtonPrimary>
          </div>
          <div className="relative z-10 mr-0 lg:mr-4 md:mr-0 sm:mr-0">
            <a
              href={
                item.id !== 3 && item.id !== 2
                  ? `/product-detail/${events?.data?.[0]?.id}`
                  : undefined
              }
              className="block"
              onClick={(event) => handleImageClick(event, item.id)}
            >
              <img
                src={item.image}
                alt={item.name}
                className="sm:w-[320px] sm:h-[180px] md:w-[710px] md:h-[210px] lg:w-[450px] lg:h-[210px] object-cover rounded-xl"
              />
              {item.id === 1 && (
                <div className="absolute top-2 left-2 bg-red-500 text-white text-md font-bold px-3 py-1 rounded-full shadow-md"></div>
              )}
            </a>
          </div>
        </div>
      </div>
    );
  };

  // Transform data to match ProductEventCardProps
  const eventWithId1 = eventData.find(
    (event) => `event.id === ${events?.data?.[0]?.id}`
  );

  const transformEventData = (event: any) => {
    return {
      id: event.id,
      name: event.name,
      image: event.image,
      event_tag: event.EventTag, // Ensure this maps correctly
      venue: event.Venue,
      date: event.Date, // Ensure this is in 'YYYY-MM-DD' format
      end_date: event.end_date,
      ticket_title: "Ticket Title", // Placeholder
      price: 0, // Placeholder
    };
  };

  const transformedEventWithId1 = eventWithId1
    ? transformEventData(eventWithId1)
    : null;

  return (
    <div
      className={`relative w-full h-[240px] sm:h-[280px] md:h-[300px] lg:h-[340px] overflow-hidden ${className}`}
    >
      <div className="relative w-full h-full flex">
        {eventData.map((_, index) => renderItem(index))}
      </div>
      <Prev
        className="absolute left-2 top-1/2 transform -translate-y-1/2 z-10 text-slate-200 bg-black bg-opacity-35 rounded-3xl"
        btnClassName="w-12 h-12 hover:border-slate-400 hover:bg-slate-900 dark:hover:border-slate-400"
        svgSize="w-6 h-6"
        onClickPrev={handleClickPrev}
      />
      <Next
        className="absolute right-2 top-1/2 transform -translate-y-1/2 z-10 text-slate-200 bg-black bg-opacity-35 rounded-3xl"
        btnClassName="w-12 h-12 hover:border-slate-400 hover:bg-slate-900 dark:hover:border-slate-400"
        svgSize="w-6 h-6"
        onClickNext={handleClickNext}
      />
      {/* Dots Navigation */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-4">
        {eventData.map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 ring ring-slate-50 rounded-full ${
              indexActive === index ? "bg-blue-500" : "bg-gray-500"
            }`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>

      {/* Render ProductEventCard for event with id 1 */}
      {transformedEventWithId1 && (
        <div className="mt-8">
          <ProductEventCard eventData={transformedEventWithId1} />
        </div>
      )}
    </div>
  );
};

export default SectionEventHero;
