// utils/cartUtils.ts

import { useDispatch } from "react-redux";
import {
  incrementQuantity,
  decrementQuantity,
  removeFromCart,
  addItem,
} from "../redux/Cart/cartSlice"; // Adjust the path to your actual action creators

const useCartActions = () => {
  const dispatch = useDispatch();

  const increment = (id: any) => {
    dispatch(incrementQuantity(id));
  };

  const decrement = (id: any) => {
    dispatch(decrementQuantity(id));
  };

  const remove = (id: any) => {
    dispatch(removeFromCart(id));
  };
  const addProduct = (data: any) => {
    dispatch(addItem(data));
  };

  return { increment, decrement, remove, addProduct };
};

export default useCartActions;
