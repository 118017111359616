import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";

interface ProductEventCardProps {
  eventData: {
    id: number;
    name: string;
    image: string;
    event_tag: string[];
    venue: string;
    date: string;
    end_date: string;
    ticket_title: string;
    price: number;
  };
  className?: string;
}

const ProductEventCard: FC<ProductEventCardProps> = ({
  eventData,
  className = "",
}) => {
  const navigate = useNavigate();
  const {
    id,
    name,
    image,
    venue,
    date,
    price,
    ticket_title,
    end_date,
  } = eventData;
  console.log(end_date);

  const handleClick = () => {
    navigate(`/product-detail/${id}`);
  };

  // const { day, date: eventDate, month } = formatDate(date);
  const formattedPrice = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price);

  return (
    <div
      className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
      data-nc-id="ProductCard"
    >
      <Link to={`/product-detail/${id}`} className="absolute inset-0"></Link>

      <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group shadow-lg">
        <Link to={`/product-detail/${id}`} className="block">
          <img
            src={image}
            alt={name}
            className="w-full h-56 sm:h-64 md:h-72 lg:h-80 xl:h-66 object-cover drop-shadow-xl"
          />

          {/* Discount Label */}
          {/* <div className="absolute top-2 left-2 bg-red-500 text-white text-md font-bold px-3 py-1 rounded-full shadow-md">
            100 ₹ Discount
          </div> */}
        </Link>
      </div>

      <div className="space-y-4 px-2.5 pt-5 pb-2.5">
        <div>
          <span className="text-lg sm:text-xl font-semibold text-slate-700">
            {name}
          </span>

          <hr className="text-slate-500 mt-3" />
          <div className="divide-x-2 flex items-center space-x-1">
            <div className="flex items-center space-x-1.5 px-1 mt-1">
              <div className="text-dark flex flex-col items-center">
                <h6 className="text-blue-950 font-medium text-lg sm:text-2xl">
                  {new Date(date).toLocaleString("default", { month: "short" })}
                </h6>
                <h4 className="text-blue-950 font-medium text-lg sm:text-2xl">
                  {new Date(date).getDate()}
                </h4>
              </div>
              <p className="text-xs sm:text-base">to</p>
              <div>
                <h6 className="text-blue-950 font-medium text-lg sm:text-2xl">
                  {new Date(end_date).toLocaleString("default", {
                    month: "short",
                  })}
                </h6>
                <h4 className="text-blue-950 font-medium text-lg sm:text-2xl">
                  {new Date(end_date).getDate()}
                </h4>
              </div>
            </div>
            <div className="px-1 mt-2 truncate-venue">
              <p className="text-xs sm:text-sm">
                <span className="text-slate-400">Venue:&nbsp;</span>
                <span>{venue}</span>
              </p>
            </div>
          </div>
          <hr className="text-slate-500 mt-3" />

          <div className="mt-2 flex justify-between items-center">
            <h1 className="font-semibold text-sm sm:text-base md:text-lg lg:text-lg">
              <span className="text-gray-500">{ticket_title}</span>{" "}
              <span className="inline-block bg-red-500 text-white px-1 py-1 rounded-badge">
                {formattedPrice}
              </span>
            </h1>
            <button
              className="ticketBtn px-3 py-1.8 bg-blue-950 text-white text-xs font-medium rounded shadow-md hover:bg-blue-800
              focus:outline-none focus:ring-2 focus:ring-blue-600 active:bg-blue-700 whitespace-nowrap"
              onClick={handleClick}
            >
              Buy Tickets
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductEventCard;
