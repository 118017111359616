import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import eventReducer from "../../redux/EventSlice/eventSlice";
import { API_ENDPOINTS } from "api/apiEndPoints";
import axios from "axios";
import { token } from "api";

export const GetCategoriesByID: any = createAsyncThunk(
  "/categories",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    if (data?.id && data?.currency && data?.page) {
      try {
        const response = await axios.get(
          `${baseUrl}/get-product-by-category/${data?.id}?currency=${data?.currency}&page=${data?.page}`
        );

        // console.log(response?.data, 'res')
        return { getCategoriesByID: response?.data };
      } catch (error) {}
    }
  }
);
export const PostCartItem: any = createAsyncThunk(
  "/CartItem",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(`${baseUrl}/products/cart`, data);
      return { postCartItem: response?.data };
    } catch (error) {}
  }
);
export const SignUpUser: any = createAsyncThunk(
  "/SignUp",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(
        `${baseUrl}${API_ENDPOINTS.register}`,
        data
      );
      return { signUpUser: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const LoginUser: any = createAsyncThunk(
  "/loginUser",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(
        `${baseUrl}${API_ENDPOINTS.login}`,
        data
      );
      return { loginUser: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const CreateOrder: any = createAsyncThunk(
  "/general/orders/create",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(
        `${baseUrl}${API_ENDPOINTS.createOrder}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return { createOrderData: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const ValidateCouponCode: any = createAsyncThunk(
  "/general/coupon-code/verify",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(
        `${baseUrl}${API_ENDPOINTS.validateCouponCode}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return { couponCodeData: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const ValidateMobileNo: any = createAsyncThunk(
  "/general/sms/verify",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(
        `${baseUrl}${API_ENDPOINTS.validateMobileNo}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return { mobileNoData: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const SendMobileNo: any = createAsyncThunk(
  "/general/sms/send",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(
        `${baseUrl}${API_ENDPOINTS.sendMobileNo}`,
        {phone:data},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return { sendMobileNoData: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const SetupDefaultcompany: any = createAsyncThunk(
  "setup/default-company",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(
        `${baseUrl}${API_ENDPOINTS.defalutCompany}`,
      );
      return { setupdefaultcompany: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetOrderDataByID: any = createAsyncThunk(
  "/GetOrderDataByID",
  async (id: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(
        `${baseUrl}${API_ENDPOINTS.orderShow}/${id}`
      );

      // console.log(response?.data, 'res')
      return { getOrderDataByID: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetEventDetail: any = createAsyncThunk(
  "hr/events/show",
  async (id: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(
        `${baseUrl}${API_ENDPOINTS.EventDetail}/${id}`
      );

      // console.log(response?.data, 'res')
      return { getEventDetailShow: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GarbaClassList: any = createAsyncThunk(
  "GarbaClass/list",
  async (data: string, thunkAPI) => {
    // Accept only `id` here
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(
        `${baseUrl}${API_ENDPOINTS.garbaclass}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return { garbaClassList: response?.data }; // Return the API response data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data); // Handle error
    }
  }
);

export const UploadFile: any = createAsyncThunk(
  "upload_file",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const { file_upload, type, module_name } = data; // Destructure the fields
    const formData = new FormData();

    // Append the file and other fields to FormData
    formData.append("file_upload", file_upload);
    formData.append("type", type);
    formData.append("module_name", module_name);

    try {
      const response = await axios.post(
        `${baseUrl}${API_ENDPOINTS.uploadFile}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Ensure correct content type for file uploads
          },
        }
      );

      return { uploadFileData: response?.data }; // Return the API response data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data); // Handle error
    }
  }
);

export const GarbaFormAddList: any = createAsyncThunk(
  "GarbaFormAddList/list",
  async (data: string, thunkAPI) => {
    // Accept only `id` here
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(
        `${baseUrl}${API_ENDPOINTS.garbaFormAdd}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        // Removed unnecessary period in URL
      );

      return { garbaFormAddList: response?.data }; // Return the API response data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data); // Handle error
    }
  }
);
export const GarbaFormEditList: any = createAsyncThunk(
  "GarbaFormEditList/list",
  async (data: any, thunkAPI) => {
    // Accept only `id` here
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.put(
        `${baseUrl}${API_ENDPOINTS.garbaFormAdd}/${data?.uuid}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        // Removed unnecessary period in URL
      );

      return { garbaFormEditList: response?.data }; // Return the API response data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data); // Handle error
    }
  }
);

export const GetICardFormByID: any = createAsyncThunk(
  "/GetICardFormByID",
  async (id: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(
        `${baseUrl}${API_ENDPOINTS.idCardById}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log(response?.data, 'res')
      return { getICardFormByID: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GarbaFormDeleteByID: any = createAsyncThunk(
  "/GarbaFormDeleteByID",
  async (id: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.delete(
        `${baseUrl}${API_ENDPOINTS.idCardById}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log(response?.data, 'res')
      return { garbaFormDeleteByID: response?.data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const ContainerDataSlice = createSlice({
  name: "ContainerDataEvent",
  initialState: {
    getCategoriesByID: [],
    postCartItem: [],
    signUpUser: [],
    loginUser: [],
    getOrderDataByID: [],
    getEventDetailShow: [],
    garbaClassList: [],
    garbaFormAddList: [],
    uploadFileData: [],
    getICardFormByID: [],
    garbaFormEditList: [],
    isLoadingGarbaClassList: false,
    garbaFormDeleteByID: [],
    setupdefaultcompany:[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetCategoriesByID.fulfilled, (state, action: any) => {
        state.getCategoriesByID = action.payload.getCategoriesByID;
      })

      .addCase(PostCartItem.pending, (state: any) => {
        state.postCartItemLoader = true;
      })
      .addCase(PostCartItem.fulfilled, (state: any, action: any) => {
        state.postCartItem = action.payload.postCartItem;
        state.postCartItemLoader = false;
      })
      .addCase(PostCartItem.rejected, (state: any) => {
        state.postCartItemLoader = false;
      })
      .addCase(SignUpUser.fulfilled, (state: any, action: any) => {
        state.signUpUser = action.payload.signUpUser;
      })
      .addCase(LoginUser.fulfilled, (state: any, action: any) => {
        state.loginUser = action.payload.loginUser;
      })
      .addCase(CreateOrder.fulfilled, (state: any, action: any) => {
        state.createOrderData = action.payload.createOrderData;
      })
      .addCase(ValidateCouponCode.fulfilled, (state: any, action: any) => {
        state.couponCodeData = action.payload.couponCodeData;
      })
      .addCase(SendMobileNo.fulfilled, (state: any, action: any) => {
        state.sendMobileNoData = action.payload.sendMobileNoData;
      })
      .addCase(ValidateMobileNo.fulfilled, (state: any, action: any) => {
        state.mobileNoData = action.payload.mobileNoData;
      })
      .addCase(SetupDefaultcompany.fulfilled, (state: any, action: any) => {
        state.setupdefaultcompany = action.payload.setupdefaultcompany;
      })
      .addCase(GetOrderDataByID.fulfilled, (state: any, action: any) => {
        state.getOrderDataByID = action.payload.getOrderDataByID;
      })
      .addCase(GetEventDetail.fulfilled, (state: any, action: any) => {
        state.getEventDetailShow = action.payload.getEventDetailShow;
      })
      .addCase(GarbaClassList.pending, (state: any) => {
        state.isLoadingGarbaClassList = true;
      })
      .addCase(GarbaClassList.fulfilled, (state: any, action: any) => {
        state.garbaClassList = action.payload.garbaClassList;
        state.isLoadingGarbaClassList = false;
      })
      .addCase(GarbaClassList.rejected, (state: any) => {
        state.isLoadingGarbaClassList = false;
      })
      .addCase(UploadFile.fulfilled, (state: any, action: any) => {
        state.uploadFileData = action.payload.uploadFileData;
      })
      .addCase(GarbaFormAddList.fulfilled, (state: any, action: any) => {
        state.garbaFormAddList = action.payload.garbaFormAddList;
      })
      .addCase(GetICardFormByID.fulfilled, (state: any, action: any) => {
        state.getICardFormByID = action.payload.getICardFormByID;
      })
      .addCase(GarbaFormEditList.fulfilled, (state: any, action: any) => {
        state.garbaFormEditList = action.payload.garbaFormEditList;
      });
  },
});

export default ContainerDataSlice.reducer;
