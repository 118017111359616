import * as Yup from "yup";
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import Logo from "../../shared/Logo/Logo";
import PlaystoreLogo from "../../Assets/PlatStore.png"
import IphoneLogo from "../../Assets/iphone-logo.png"
import EventImage from '../../Assets/Garba_Couple_2024.png'
import { SweetToast } from "utils/sweetAlert";

interface FormData {
  organizationName: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
  gender: string;
  password: string;
  confirmPassword: string;
  address: string;
  zip: string;
  state: string;
  city: string;
  acceptPolicy: boolean;
}

const initialValues: FormData = {
  organizationName: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  country: "IN",
  gender: "",
  password: "",
  confirmPassword: "",
  address: "",
  zip: "",
  state: "",
  city: "",
  acceptPolicy: false,
};

const validationSchema = Yup.object({
  organizationName: Yup.string()
    .min(6, "Organization Name must be at least 6 characters")
    .required("Organization Name is required"),

  firstName: Yup.string()
    .min(6, "First Name must be at least 6 characters")
    .required("First Name is required"),

  lastName: Yup.string()
    .min(6, "Last Name must be at least 6 characters")
    .required("Last Name is required"),

  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),

  phone: Yup.string()
    .matches(/^\d{10}$/, "Phone must be exactly 10 digits")
    .required("Phone is required"),

  gender: Yup.string().required("Gender is required"),

  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords do not match")
    .required("Confirm Password is required"),

  address: Yup.string().required("Address is required"),

  zip: Yup.string()
    .matches(/^\d{6}$/, "Zip Code must be exactly 6 digits")
    .required("Zip is required"),

  state: Yup.string().required("State is required"),

  city: Yup.string().required("City is required"),

  acceptPolicy: Yup.boolean().oneOf(
    [true],
    "You must accept the Privacy Policy"
  ),
});

const ProductDetailPage: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = (values: FormData) => {
    console.log("Form data submitted:", values);
    SweetToast.success("Form successfully submitted");
    // Navigate to login page after successful submission
  };
  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <div className="container bg-[#f1f1f1]">
      <div className="bg-white rounded-lg shadow-lg p-6 mb-3 max-w-4xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col items-center justify-center">
            <Logo className="mb-4 w-42 h-35 transition-transform duration-300 ease-in-out hover:scale-105 hover:opacity-80"/>
            <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-2 text-blue-900">
              Register as Organizer
            </h2>
            <p className="text-sm sm:text-lg md:text-xl lg:text-1xl text-orange-500 mb-4 text-justify">
              Register yourself as organizer and explore the endless options to
              manage your event with Endless Event
            </p>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="space-y-4">
                  <div>
                    <label
                      htmlFor="organizationName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Organization Name <span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="text"
                      id="organizationName"
                      name="organizationName"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <ErrorMessage
                      name="organizationName"
                      component="p"
                      className="text-red-500 text-xs mt-1"
                    />
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        First Name <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="text"
                        id="firstName"
                        name="firstName"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="p"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="lastName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Last Name <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="text"
                        id="lastName"
                        name="lastName"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="p"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email <span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <ErrorMessage
                      name="email"
                      component="p"
                      className="text-red-500 text-xs mt-1"
                    />
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <div>
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Country
                      </label>
                      <Field
                        as="select"
                        id="country"
                        name="country"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value="IN">India</option>
                        {/* Add other options here */}
                      </Field>
                    </div>
                    <div>
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Phone <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="tel"
                        id="phone"
                        name="phone"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <ErrorMessage
                        name="phone"
                        component="p"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="gender"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Gender <span className="text-red-500">*</span>
                      </label>
                      <Field
                        as="select"
                        id="gender"
                        name="gender"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </Field>
                      <ErrorMessage
                        name="gender"
                        component="p"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="password"
                        id="password"
                        name="password"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <ErrorMessage
                        name="password"
                        component="p"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="confirmPassword"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Confirm Password <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component="p"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Address <span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="text"
                      id="address"
                      name="address"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <ErrorMessage
                      name="address"
                      component="p"
                      className="text-red-500 text-xs mt-1"
                    />
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <div>
                      <label
                        htmlFor="zip"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Zip Code <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="text"
                        id="zip"
                        name="zip"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <ErrorMessage
                        name="zip"
                        component="p"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="state"
                        className="block text-sm font-medium text-gray-700"
                      >
                        State <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="text"
                        id="state"
                        name="state"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <ErrorMessage
                        name="state"
                        component="p"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="city"
                        className="block text-sm font-medium text-gray-700"
                      >
                        City <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="text"
                        id="city"
                        name="city"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <ErrorMessage
                        name="city"
                        component="p"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      <div className="flex items-center">
                        <Field
                          type="checkbox"
                          name="acceptPolicy"
                          className="mr-2" // Add margin-right to create space between the checkbox and text
                        />
                        <span>
                          I accept the Privacy Policy{" "}
                          <span className="text-red-500">*</span>
                        </span>
                      </div>
                    </label>
                    <ErrorMessage
                      name="acceptPolicy"
                      component="p"
                      className="text-red-500 text-xs mt-1"
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-full bg-blue-600 text-white font-medium py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors"
                    disabled={isSubmitting}
                  >
                    Register
                  </button>
                </Form>
              )}
            </Formik>
            <p className="mt-3 text-base text-gray-800">
              Already have an account?{" "}
              <span className="text-blue-800" onClick={handleLogin}>
                Login here
              </span>
            </p>
            <div className="space-y-4">
              <h4 className="mt-3 lg:text-2xl sm:text-base font-medium text-gray-800">
                Download Our Organizer App
              </h4>
              <div className="flex flex-wrap space-x-4 justify-center items-center gap-2">
                <img src={PlaystoreLogo} alt="PlaystoreLogo" className="w-6 h-6"/>
                <img src={IphoneLogo} alt="IphoneLogo" className="w-6 h-6"/>
              </div>
            </div>
          </div>
          <div className="mb-4 flex flex-col items-center justify-center">
            <h3 className="mb-2 text-2xl font-semibold leading-tight">
              Welcome to,
            </h3>

            <h2 className="mb-5 text-4xl font-bold leading-tight text-blue-900">
              Eka Event
            </h2>

            <div className="w-full flex justify-center">
              <img src={EventImage} alt="" className="w-[300px] h-[300px] transition-transform duration-300 ease-in-out hover:scale-105" />
            </div>
            <div className="p-4">
              <p className="text-base text-gray-700 text-justify">
                Use Endless Event to take command of your event planning
                process. Event organizers can easily create, administer, and
                promote their events with secure payment processing thanks to
                our user-friendly admin interface. Integrate social media
                campaigns seamlessly, produce thorough reports, and interact
                with guests in a way that has never been possible before. Join
                our active community of event professionals to see your events
                flourish!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailPage;
